import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const useDeviceMediaQuery = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const isMedium = useMediaQuery(theme.breakpoints.only('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const isHDScreen = useMediaQuery(theme.breakpoints.down(1300))
  const isFullHDScreen = useMediaQuery(theme.breakpoints.up(1376))

  return { isMobile, isMedium, isTablet, isDesktop, isHDScreen, isFullHDScreen }
}
