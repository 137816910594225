import { colors } from 'styles'

export const TabsWithPanelStyles = {
  tabs: (isChapterTabs: boolean | undefined) => ({
    marginTop: isChapterTabs ? '20px' : '0',
    marginLeft: isChapterTabs ? '0' : '-12px',
    paddingBottom: isChapterTabs ? '0' : '20px',
    borderBottom: `1px solid ${colors.backgroundMain}`,
    backgroundColor: isChapterTabs ? colors.backgroundPrimary : 'transparent',
  }),
}
