import { makeAutoObservable } from 'mobx'
import { Notification, NotificationResponse, notificationsApi } from '@/api/notifications'
import { addAxiosErrorNotification } from '@/components/Notifications'
import { NotificationSettingsType } from '@/types'
import { NotificationParams, ReadNotificationAllParams } from './types'
class NotificationsStore {
  notifications: NotificationResponse | null = null
  notificationLoader = false
  notificationPage = 1
  singleNotification: Notification | null = null
  singleNotificationLoader = false
  isNotificationSettings = false
  notificationSettings: NotificationSettingsType[] = []
  checkedNotificationSettings: number[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setSingleNotificationLoader = (params: boolean) => {
    this.singleNotificationLoader = params
  }

  setSingleNotification = (params: Notification | null) => {
    this.singleNotification = params
  }

  setNotificationPage = (params: number) => {
    this.notificationPage = params
  }

  setNotificationLoader = (params: boolean) => {
    this.notificationLoader = params
  }

  setNotifications = (params: NotificationResponse | null) => {
    this.notifications = params
  }

  setNotificationSettings = (settings: NotificationSettingsType[]) => {
    this.notificationSettings = settings
  }

  setIsNotificationSettings = (value: boolean) => {
    this.isNotificationSettings = value
  }

  setCheckedNotifications = (value: number[]) => {
    this.checkedNotificationSettings = value
  }

  getNotification = (params: number) =>
    notificationsApi
      .getNotification(params)
      .then((res) => {
        if (res.success) {
          this.setSingleNotification(res.data)
        }
      })
      .finally(() => {
        this.setSingleNotificationLoader(false)
      })

  readNotification = (params: number, data?: ReadNotificationAllParams) =>
    notificationsApi.readNotification(params, data).catch(addAxiosErrorNotification)

  getNotificationSettings = () =>
    notificationsApi
      .getNotificationSettings()
      .then((response) => {
        if (response.success && response.data) {
          this.setNotificationSettings(response.data.data)
          this.setCheckedNotifications(response.data.data.filter((data) => data.isEnable).map((item) => item.id))
        }
      })
      .catch(addAxiosErrorNotification)

  getNotifications = (params: NotificationParams) =>
    notificationsApi
      .getNotifications(params)
      .then((res) => {
        if (res.success) {
          this.setNotifications(res.data)
        }
      })
      .catch(addAxiosErrorNotification)
      .finally(() => {
        this.setNotificationLoader(false)
      })
}

export const notificationsStore = new NotificationsStore()
