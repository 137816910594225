import { INetworkConfig, Instance } from '@/api/instance'
import { IResponse } from '@/api/types'
import { Endpoints } from '../endpoints'
import {
  IAwardsStatistics,
  IGetAwardsResponse,
  IGetStatisticsMethodsResponse,
  IPointerCategories,
  IStudentAwardsParams,
} from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class PointerApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getPointerCategories = (): Promise<IResponse<IPointerCategories>> => this.get(Endpoints.Categories)

  getAwardsStatistic = (): Promise<IResponse<IAwardsStatistics>> => this.get(Endpoints.AwardsStatistic)

  getStudentStatisticsMethods = (): Promise<IResponse<IGetStatisticsMethodsResponse[]>> =>
    this.get(Endpoints.StatisticsMethods)

  getStudentAwards = (params: IStudentAwardsParams): Promise<IResponse<IGetAwardsResponse>> =>
    this.get(Endpoints.Awards, { params })
}

export const pointerApi = new PointerApi(config)
