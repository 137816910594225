import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useStores } from '@/stores'

export const useAuthBootstrap = () => {
  const { authStore, profileStore, gamificationOfferStore, appStore } = useStores()
  const [isInitiated, setInitiatedStatus] = useState(true)
  const location = useLocation()

  const getUserData = async () => {
    try {
      await profileStore.getProfile()
      await gamificationOfferStore.getGamificationOffer()
      await appStore.getNotificationsCount()
    } catch (error: Error | unknown) {
      console.error(error)
    } finally {
      setInitiatedStatus(false)
    }
  }

  useEffect(() => {
    if (authStore.isAuth) {
      getUserData()
    }
  }, [location.pathname])

  return [isInitiated]
}
