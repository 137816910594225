import { FC, memo } from 'react'
import { Control, useController } from 'react-hook-form'
import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import { Icons } from '@/components/Icons'
import { selectFieldStyles } from './styles'

type TFieldProps = {
  control: Control<any>
  label?: string
  name: string
  options: {
    label: string
    value: string
  }[]
}

type TProps = TFieldProps & SelectProps

export const SelectField: FC<TProps> = memo(({ control, label, name, options, ...restProps }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name })
  const fieldError = error && error.message ? error.message : error

  return (
    <FormControl sx={selectFieldStyles} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        name={field.name}
        error={!!fieldError}
        onBlur={field.onBlur}
        onChange={field.onChange}
        IconComponent={Icons.ArrowDown}
        {...restProps}
      >
        <MenuItem value="">
          <em>Нет</em>
        </MenuItem>
        {options.map(({ label, value }) => (
          <MenuItem key={`${value}${label}`} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
})
