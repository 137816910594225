import { Components } from '@mui/material/styles/components'
import { colors } from '@/styles/colors'

export const chip: Components['MuiChip'] = {
  styleOverrides: {
    colorPrimary: {
      color: colors.textWhite,
    },
  },
}
