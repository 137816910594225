import { Endpoints } from '@/api/endpoints'
import { INetworkConfig, Instance } from '@/api/instance'
import { SentOtpReq, VerifyOtpReq } from '@/stores/profile/types'
import { IResponse } from '../types'
import { Contract, IChangePassword, IProfile, IProfileUpdateParams, ISuccessResponse, IUploadImage } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class Profile extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getContract = (id: string): Promise<IResponse<Contract>> => this.get(`${Endpoints.Contracts}/${id}`)

  getProfile = (): Promise<IResponse<IProfile>> => this.get(Endpoints.Profile)

  updateProfile = (params: IProfileUpdateParams): Promise<IResponse<ISuccessResponse>> =>
    this.put(Endpoints.Profile, params)

  sentOtp = (params: SentOtpReq): Promise<IResponse<ISuccessResponse>> => this.post(Endpoints.ContractSentOtp, params)

  changeLogin = (newLogin: string): Promise<IResponse<IProfile>> =>
    this.post(Endpoints.ProfileChangeLogin, { newLogin })

  changeProfilePassword = (params: IChangePassword): Promise<IResponse<IProfile>> =>
    this.post(Endpoints.ProfileChangePassword, params)

  uploadProfile = (params: FormData): Promise<IResponse<IUploadImage[]>> =>
    this.post(Endpoints.UploadImageProfile, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  accept = (params: VerifyOtpReq): Promise<IResponse<ISuccessResponse>> => this.post(Endpoints.ContractAccept, params)

  getMyContracts = (): Promise<IResponse<Contract[]>> => this.get(Endpoints.MyContracts)
}

export const profileApi = new Profile(config)
