import { useLocation, useNavigate } from 'react-router'
import { observer } from 'mobx-react-lite'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { menuItems } from '@/modules/layout/constants'
import { menuStyles } from './styles'

type Props = {
  open: boolean
}

export const Menu = observer(({ open }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleMenuLinkClick = (path: string) => {
    navigate(path)
  }

  const activeLink = `/${location.pathname?.split('/')[1]}`

  return (
    <List sx={menuStyles.menuListWrapper}>
      {menuItems?.map((item) => (
        <ListItem
          key={item?.path}
          disablePadding
          sx={{
            ...menuStyles.menuList,
            ...(activeLink === item?.path ? menuStyles.menuActiveList : {}),
          }}
        >
          <ListItemButton
            onClick={handleMenuLinkClick.bind(null, item?.path)}
            sx={{
              ...menuStyles.menuButtons,
              ...(!open ? menuStyles.menuJustify : {}),
            }}
          >
            <ListItemIcon sx={menuStyles.menuJustify}>{item?.icon}</ListItemIcon>

            {open && <ListItemText primary={item?.label} />}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
})
