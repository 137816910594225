import { makeAutoObservable, observable } from 'mobx'
import { authApi } from '@/api'
import { studentInfo } from '@/pages/Login/constants'
import { storage } from '@/utils/storage'
import { IStudentInfo, LogOut, SignInParamsType, TokenType } from './types'

class AuthStore {
  isAuth: boolean | null = null
  token: TokenType | null = null
  userInfo: IStudentInfo | null = null
  requestLoading = false
  gamificationOfferUpdatedTime: string | null = null

  constructor() {
    makeAutoObservable(this, {
      isAuth: observable,
    })
  }

  setRequestLoading = (params: boolean) => {
    this.requestLoading = params
  }

  logOut = (params: LogOut) => authApi.logOut(params)

  getSignIn = ({ login, password, fcmToken }: SignInParamsType) =>
    authApi.getSignIn({ login, password, fcmToken }).then((response) => {
      if (response.success) {
        storage.set('accessToken', response.data.accessToken)
        storage.set('refreshToken', response.data.refreshToken)
        storage.setObject<IStudentInfo>(studentInfo, response.data.studentInfo)

        this.setUserInfo(response.data.studentInfo)
        this.setToken({
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        })
        this.setIsAuth(true)
      }

      return response
    })

  setUserInfo = (userInfo: IStudentInfo) => {
    this.userInfo = userInfo
  }

  setToken = (token: TokenType) => {
    this.token = token
  }

  setIsAuth = (isAuth: boolean) => {
    this.isAuth = isAuth
  }

  setGamificationOfferUpdatedTime = (time: string) => {
    this.gamificationOfferUpdatedTime = time
  }

  reset = () => {
    this.isAuth = null
    this.token = null
    this.userInfo = null
    this.gamificationOfferUpdatedTime = null
  }
}

export const authStore = new AuthStore()
