import { Components } from '@mui/material/styles/components'
import { colors } from '@/styles/colors'

export const card: Components['MuiCard'] = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      textTransform: 'inherit',
      boxShadow: 'none',
      color: colors.textPrimary,
    },
  },
}
