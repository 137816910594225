import { alpha } from '@mui/system'
import { colors } from '@/styles'

export const headerStyles = {
  header: {
    padding: '15px 29px',
    backgroundColor: colors.backgroundPrimary,
    boxShadow: 'none',
  },
  headerLeft: {
    display: 'flex',
    gap: '50px',
    alignItems: 'center',
  },
  logo: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '25px',
  },
  headerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuButton: {
    padding: '5px',
    minWidth: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: alpha(colors.mainPrimary, 0.9),

    '&:hover': {
      backgroundColor: colors.mainPrimary,
      boxShadow: 'none',
      opacity: '1',
    },

    '.MuiButton-startIcon': {
      margin: 0,
    },
  },
  backDropFilter: {
    backdropFilter: 'blur(8px)',
  },
}
