import { colors } from '@/styles'

export const menuStyles = {
  menuList: {
    padding: 0,
    display: 'flex',
  },
  menuActiveList: {
    backgroundColor: colors.backgroundMain,
    color: colors.textWhite,
  },
}
