import { makeAutoObservable } from 'mobx'
import { paymentApi } from '@/api/payment'
import { IPaymentData, IPaymentsParams, PaidStatus } from '@/api/payment/types'
import { addAxiosErrorNotification } from '@/components/Notifications'

class PaymentsStore {
  payments: IPaymentData[] = []
  from: string | null = null
  to: string | null = null
  state: PaidStatus | undefined = undefined
  page = 1
  perPage = 10

  constructor() {
    makeAutoObservable(this)
  }

  getPayments = (params: IPaymentsParams) =>
    paymentApi
      .getPayments(params)
      .then((res) => {
        if (res.success && res.data) {
          this.payments = res.data?.payments
        }

        return res
      })
      .catch(addAxiosErrorNotification)

  setState = (state: PaidStatus | undefined) => {
    this.state = state
  }

  setFrom = (from: string | null) => {
    this.from = from
  }

  setTo = (to: string | null) => {
    this.to = to
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  reset() {
    this.payments = []
  }
}

export const paymentStore = new PaymentsStore()
