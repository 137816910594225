import { useCallback, useEffect, useRef } from 'react'
import { toast, ToastOptions, UpdateOptions } from 'react-toastify'
import { AxiosError } from 'axios'
import { IResponse } from '@/api/types'
import mainDictionary from '@/dictionary'
import { dictionaryWithKeys } from '@/utils'
import { makeErrMsg } from '@/utils/general'
import { NotificationToast } from './notification-toast'
import {
  NotificationsTypes,
  RepeatingErrorNotificationReturnType,
  RepeatingNotificationReturnType,
  TError,
} from './types'

export const addNotification = (message: string, options?: ToastOptions): string | number =>
  toast(
    ({ closeToast }) => (
      <NotificationToast closeToast={closeToast} title={message} type={options?.type as NotificationsTypes} />
    ),
    options,
  )

export const addAxiosErrorNotification = (message: AxiosError<IResponse<any>>) => {
  toast.error(({ closeToast }) => (
    <NotificationToast closeToast={closeToast} title={makeErrMsg(message)} type={NotificationsTypes.Error} />
  ))
}

export const removeNotification = (id: string | number | undefined): void => {
  toast.dismiss(id)
}

export const updateNotification = (id: string | number, options?: UpdateOptions): void => {
  toast.update(id, options)
}

export const useRepeatingNotification = (isOnUnmount?: boolean): RepeatingNotificationReturnType => {
  const idRef = useRef<string | number>('')

  const clearNotificationId = useCallback(() => {
    idRef.current = ''
  }, [])

  useEffect(
    () => () => {
      if (isOnUnmount && idRef.current) {
        removeNotification(idRef.current)
        idRef.current = ''
      }
    },
    [],
  )

  return (message: string, options?: ToastOptions) => {
    if (idRef.current) {
      updateNotification(idRef.current)
    } else {
      idRef.current = addNotification(message, {
        ...options,
        hideProgressBar: false,
        onClose: clearNotificationId,
      })
    }
  }
}

export const useRepeatingErrorNotification = (isOnUnmount?: boolean): RepeatingErrorNotificationReturnType => {
  const idRef = useRef<string | number>('')

  const clearNotificationId = useCallback(() => {
    idRef.current = ''
  }, [])

  useEffect(
    () => () => {
      if (isOnUnmount && idRef.current) {
        removeNotification(idRef.current)
        idRef.current = ''
      }
    },
    [],
  )

  return (error: TError, options?: ToastOptions) => {
    if (idRef.current) {
      updateNotification(idRef.current)
    } else {
      // eslint-disable-next-line max-len
      const message = `${dictionaryWithKeys(mainDictionary.errorCode, { code: error.code })}. ${dictionaryWithKeys(
        mainDictionary.errorDescription,
        { text: error.text },
      )}`

      idRef.current = addNotification(message, {
        ...options,
        type: NotificationsTypes.Error,
        hideProgressBar: false,
        onClose: clearNotificationId,
      })
    }
  }
}
