import { Icons } from '@/components'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { IMenuItems } from './menu/types'

export const drawerWidth = 240

export const menuItems: IMenuItems[] = [
  {
    label: mainDictionary.dashboard,
    path: ROUTES.dashboard,
    icon: <Icons.HomeIcon />,
  },
  {
    label: mainDictionary.payments,
    path: ROUTES.payments,
    icon: <Icons.PaymentIcon />,
  },
  {
    label: mainDictionary.groups,
    path: ROUTES.groups,
    icon: <Icons.GroupsIcon />,
  },
  {
    label: mainDictionary.pointer,
    path: ROUTES.pointers,
    icon: <Icons.InsertChartOutlinedTwoToneIcon />,
  },
  {
    label: mainDictionary.rating,
    path: ROUTES.rating,
    icon: <Icons.BarChartIcon />,
  },
  // {
  //   label: mainDictionary.certificates,
  //   path: ROUTES.certificates,
  //   icon: <Icons.NewspaperIcon />,
  // },
  {
    label: mainDictionary.shop,
    path: ROUTES.shop,
    icon: <Icons.ShoppingCartIcon />,
  },
  {
    label: mainDictionary.additionalLessons,
    path: ROUTES.additionalLessons,
    icon: <Icons.CastForEducationIcon />,
  },
  {
    label: mainDictionary.settings,
    path: ROUTES.profile,
    icon: <Icons.SettingsOutlinedIcon />,
  },
]
