import { alpha } from '@mui/material/styles'
import { colors, general } from '@/styles'

export const TableStyles = {
  head: {
    backgroundColor: colors.backgroundDarkGray,
  },
  tableHeadRow: {
    fontWeight: '600',
  },
  tableCell: {
    padding: '10px',
  },
  lastRow: {
    backgroundColor: { xs: colors.backgroundGreenLight, lg: alpha(colors.backgroundGreen, 0.3) },
    '&.MuiTableRow-root:hover': {
      // eslint-disable-next-line max-len
      backgroundColor: {
        xs: colors.backgroundGreenLight,
        lg: `${alpha(colors.backGroundTableLight, 0.35)} !important`,
      },
    },
  },
  lastRowColumn: {
    color: colors.textBlack,
  },

  headRow: {
    fontWeight: 600,
    minWidth: { xs: 150, sm: general.inherit },
    padding: '15px',
  },

  pointer: {
    cursor: general.pointer,
  },
  bigTableWrapper: {
    marginBottom: '50px',
  },
  pagination: {
    borderTop: `10px solid ${colors.backgroundMain}`,
    '& .MuiTablePagination-toolbar': {
      flexWrap: { xs: 'wrap', sm: 'inherit' },
      pl: { xs: '11px', sm: 'inherit' },
      '& .MuiInputBase-colorPrimary': {
        ml: { xs: 'auto', sm: 'inherit' },
        mr: { xs: '5px', sm: 'inherit' },
      },
      '& .MuiTablePagination-displayedRows': {
        width: { xs: '73%', sm: 'inherit' },
      },
      '& .MuiTablePagination-actions': {
        ml: { xs: 'auto', sm: '20px' },
        '& button': {
          p: { xs: '8px' },
        },
      },
    },
  },
  emptyData: {
    fontSize: '20px',
    padding: '15px 0',
    textAlign: 'center',
  },
}
