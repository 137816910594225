import { colors } from '@/styles/colors'

export const mobileTableStyles = {
  '& table, thead, tbody, th, td, tr': {
    display: 'block',
  },
  '& thead tr': {
    position: 'absolute',
    top: '-9999px',
    left: '-9999px',
  },
  '& tr:not(:last-child)': {
    display: 'block',
    borderBottom: `10px solid ${colors.backgroundMain}`,
  },
  '& td': {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'none',
    borderBottom: `1px solid ${colors.backgroundMain}`,
    padding: '5px 11px',
  },
  '& td: before': {
    top: '0',
    left: '6px',
    width: '45%',
    paddingRight: '10px',
    whiteSpace: 'nowrap',
  },
  '& td[data-th]:before': {
    content: 'attr(data-th)',
    fontWeight: 'bold',
    padding: '10px 0',
  },

  '& td span': {
    padding: '10px 0',
  },
}

export const mobileLastRowsStyles = {
  display: 'inline-flex',
  flexDirection: 'column',
  width: '33.333%',
  textAlign: 'center',
  border: `1px solid ${colors.backgroundMain}`,
  padding: '0',
  '& span': {
    display: 'block',
    width: '100%',
    borderTop: `1px solid ${colors.backgroundMain}`,
  },
}
