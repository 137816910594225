import { TableCell, TableRow } from '@mui/material'
import { TableStyles } from './styles'
import { DataRow, TableColumn, TableHoverCursor } from './types'

type Props = {
  row: DataRow
  handleBodyRowClick?: (row: DataRow) => void
  index: number
  columns: TableColumn[]
  last?: boolean
  pointer?: TableHoverCursor
  fail?: boolean
}

export const TableBodyRow = ({ row, handleBodyRowClick, columns, index, last = false, pointer }: Props) => {
  const handleRowBodyClick = () => {
    handleBodyRowClick?.(row)
  }

  return (
    <TableRow key={row.id} onClick={handleRowBodyClick} hover sx={last ? TableStyles.lastRow : {}}>
      {columns.map((column: TableColumn) => (
        <TableCell
          data-th={column.label}
          key={column.key}
          sx={{
            ...(TableHoverCursor.Pointer === pointer ? TableStyles.pointer : {}),
            ...(last ? TableStyles.lastRowColumn : {}),
            ...TableStyles.tableCell,
          }}
        >
          {column.render ? column.render(row[column.key], row, index) : row[column.key]}
        </TableCell>
      ))}
    </TableRow>
  )
}
