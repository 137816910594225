import { INetworkConfig, Instance } from '@/api/instance'
import { IResponse } from '@/api/types'
import { LogOut } from '@/stores/auth/types'
import { Endpoints } from '../endpoints'
import { IGetRefreshTokenResponse, ISignInResponse, SignInParamsType } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class AuthApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getSignIn = ({ login, password, fcmToken }: SignInParamsType): Promise<IResponse<ISignInResponse>> =>
    this.post(Endpoints.SignIn, { login, password, fcmToken })

  getRefreshToken = (): Promise<IResponse<IGetRefreshTokenResponse>> => this.get(Endpoints.RefreshToken)

  logOut = (params: LogOut): Promise<IResponse<string>> => this.post(Endpoints.AuthLogOut, params)
}

export const authApi = new AuthApi(config)
