import { inputLabelClasses } from '@mui/material/InputLabel'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { selectClasses } from '@mui/material/Select'

export const selectFieldStyles = {
  [`& .${selectClasses.icon}`]: {
    top: '20px',
    right: '10px',
  },

  [`& .${outlinedInputClasses.root}`]: {
    borderRadius: '8px',
  },

  [`& .${outlinedInputClasses.input}`]: {
    padding: '20px 14px 4px 16px',
  },

  [`& .${inputLabelClasses.root}`]: {
    transform: 'translate(16px, 12px) scale(1)',

    '&[data-shrink=true]': {
      transform: 'translate(16px, 5px) scale(.75)',
    },
  },
}
