import { useLocation, useNavigate } from 'react-router'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { menuItems } from '../constants'
import { styles } from '../styles'
import { menuStyles } from './styles'

type Props = {
  open: boolean
}

export const Menu = ({ open }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleMenuLinkClick = (path: string) => {
    navigate(path)
  }

  const activeLink = `/${location.pathname?.split('/')[1]}`

  return (
    <List sx={menuStyles.menuList}>
      {menuItems?.map((item) => (
        <ListItem
          key={item?.path}
          disablePadding
          sx={{
            ...styles.menuList,
            ...(activeLink === item?.path ? styles.menuActiveList : {}),
          }}
        >
          <ListItemButton
            onClick={handleMenuLinkClick.bind(null, item?.path)}
            sx={{
              ...styles.menuButtons,
              ...(!open ? styles.menuJustify : {}),
            }}
          >
            <ListItemIcon sx={styles.menuJustify}>{item?.icon}</ListItemIcon>

            {open && <ListItemText primary={item?.label} />}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
