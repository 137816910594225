export enum ProductStatuses {
  Available = 'available',
  Canceled = 'discontinued',
}

export type GetProductsParams = {
  page?: number
  perPage?: number
  status?: ProductStatuses | string
  categoryId?: string | null
  minPrice?: number | null
  maxPrice?: number | null
  name?: string
  id?: number
}

export type GetCategoriesParams = {
  page: number
  perPage: number
}

export enum TransactionStates {
  Pending = 'pending',
  Canceled = 'canceled',
  Delivered = 'delivered',
}

export type GetTransactionsParams = {
  page?: number
  perPage?: number
  categoryId?: string | null
  minAmount?: number | null
  maxAmount?: number | null
  itemName?: string
  id?: number
  state?: TransactionStates | string
}
