import { Navigate, Outlet } from 'react-router-dom'
import { useAuthBootstrap } from '@/App/useAuthBootstrap'
import { Loading } from '@/components'
import { ROUTES } from '@/constants'

type Props = {
  isRedirectToOfferPage: boolean
}

export const ProtectedOutlet = ({ isRedirectToOfferPage }: Props) => {
  const [isInitiated] = useAuthBootstrap()

  if (isInitiated) {
    return <Loading />
  }

  if (isRedirectToOfferPage) {
    return (
      <>
        <Navigate to={ROUTES.gamificationOffer} />
        <Outlet />
      </>
    )
  }

  return <Outlet />
}
