import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Endpoints, stage } from '@/api/endpoints'
import { IResponse } from '@/api/types'
import { resetStores, stores } from '@/stores'
import { INetworkConfig } from './types'

export class Instance {
  protected readonly instance: AxiosInstance
  protected baseURL = ''

  public constructor({ baseURL = Endpoints.Base, headers, timeout = 65000 }: INetworkConfig) {
    this.instance = axios.create({
      baseURL,
      timeout,
      headers,
    })
    // @ts-ignore
    this.instance.interceptors.request.use(this.handleRequest)
    this.instance.interceptors.response.use(this.handleResponse, this.handleResponseError)
    this.baseURL = baseURL
  }

  handleResponse = <T>(response: AxiosResponse<IResponse<T>>) => {
    const { success, operation } = response.data

    if (success === undefined || operation) {
      stores.appStore.setRenderStatus(true)
    }

    return response
  }

  private handleResponseError = async (error: AxiosError<IResponse<any>>) => {
    stores.appStore.setRenderStatus(true)

    if (error.response?.status === 401) {
      resetStores()

      window.localStorage.clear()
      window.location.reload()

      return
    }

    throw error
  }

  private handleRequest = async ({ headers, ...restConfig }: AxiosRequestConfig) => {
    const accessToken = stores.authStore.token?.accessToken

    return {
      headers: {
        ...headers,
        ...(accessToken && { Authorization: `Bearer ${stores.authStore.token?.accessToken}` }),
      },
      ...restConfig,
    }
  }

  public async post<T>(url: string, params?: any, config?: AxiosRequestConfig) {
    const { data } = await this.instance.post<T>(url, params, { ...config, baseURL: `${stage.apiUrl}${this.baseURL}` })

    return data
  }

  public async get<T>(url: string, params?: any) {
    const { data } = await this.instance.get<T>(url, { ...params, baseURL: `${stage.apiUrl}${this.baseURL}` })

    return data
  }

  public async put<T>(url: string, params?: any, config?: AxiosRequestConfig) {
    const { data } = await this.instance.put<T>(url, params, { ...config, baseURL: `${stage.apiUrl}${this.baseURL}` })

    return data
  }

  public async patch<T>(url: string, params?: any, config?: AxiosRequestConfig) {
    const { data } = await this.instance.patch<T>(url, params, { ...config, baseURL: `${stage.apiUrl}${this.baseURL}` })

    return data
  }
}
