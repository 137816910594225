import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export const changeHash = (hash: string): void => {
  try {
    window.location.hash = hash
  } catch (err) {
    console.error('Error to change hash:', err)
  }
}

export const getWindowLocationHashId = () => {
  const originHash = window.location.hash.replace('#', '')

  return parseInt(originHash, 10)
}
