import { Control, Controller, ControllerProps, Path } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { Box, TextField } from '@mui/material'
import { Autocomplete, TAutocompleteOption, TAutocompleteProps } from '@/components/Inputs'

type TProps<TFormValues extends FieldValues> = {
  control: Control<TFormValues>
  disabled?: boolean
  label: string
  name: Path<TFormValues>
  options: TAutocompleteOption[]
  onFiledChange?: (data: TAutocompleteOption) => void
}

export const AutocompleteFiled = <TFormValues extends FieldValues>({
  control,
  disabled,
  label,
  name,
  options,
  onFiledChange,
}: TProps<TFormValues>) => {
  const render: ControllerProps<TFormValues>['render'] = ({
    field: { ref, onChange, onBlur, value, ...fieldRest },
    fieldState: { error },
  }) => {
    const fieldError = error && error.message ? error.message : error

    const renderOption: TAutocompleteProps['renderOption'] = (props, option) => (
      <Box {...props} component="li" key={option.value}>
        {option.label}
      </Box>
    )

    const handleIsOptionEqualToValue: TAutocompleteProps['isOptionEqualToValue'] = (option, value) =>
      option.value === value.value

    const getOptionLabel = (option: string | TAutocompleteOption) => (typeof option !== 'string' ? option.label : '')

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const handleChange: TAutocompleteProps['onChange'] = (_, data) => {
      onChange(data)
      // @ts-ignore
      onFiledChange?.(data as TAutocompleteOption)
    }

    const renderInput: TAutocompleteProps['renderInput'] = (params) => (
      <TextField
        {...params}
        {...fieldRest}
        label={label}
        inputRef={ref}
        error={!!fieldError}
        variant="filled"
        color="primary"
        fullWidth
      />
    )

    return (
      <Autocomplete
        disabled={disabled}
        onBlur={onBlur}
        options={options}
        value={value || undefined}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={handleIsOptionEqualToValue}
        onChange={handleChange}
        renderInput={renderInput}
        renderOption={renderOption}
      />
    )
  }

  return <Controller name={name} control={control} render={render} />
}
