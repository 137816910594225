import { useState } from 'react'
import { useNavigate } from 'react-router'
import { observer } from 'mobx-react-lite'
import { Logout } from '@mui/icons-material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Badge, Box, Button, Grid, Portal, Typography } from '@mui/material'
import { IconButton, Icons, Modal } from '@/components'
import { Loading } from '@/components/Loading'
import { ROUTES } from '@/constants'
import mainDictionary from '@/dictionary'
import { useStores } from '@/stores'
import { storage } from '@/utils/storage'
import { styles } from './styles'

export const User = observer(() => {
  const { appStore, authStore } = useStores()
  const [openModal, setOpenModal] = useState(false)
  const [openPortal, setOpenPortal] = useState(false)
  const navigate = useNavigate()

  const logout = async () => {
    const token = storage.get('fcmToken')

    if (token) {
      const info = {
        fcmToken: token,
      }

      await authStore.logOut(info)
    }

    setOpenModal(false)
    setOpenPortal(false)
    storage.clearAll()
    window.location.reload()
  }

  const handleModalOpen = () => {
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const handleLogoutClick = async () => {
    setOpenPortal(true)

    logout()
  }

  const handleNotificationsClick = () => {
    navigate(ROUTES.notifications)
  }

  const dialogActions = (
    <>
      <Button onClick={handleModalClose}>{mainDictionary.no}</Button>
      <Button variant="contained" onClick={handleLogoutClick}>
        {mainDictionary.yes}
      </Button>
    </>
  )

  return (
    <>
      <Box sx={styles.buttonsWrapper}>
        <Badge badgeContent={appStore.notificationCount} color="error">
          <IconButton onClick={handleNotificationsClick}>
            <NotificationsIcon />
          </IconButton>
        </Badge>

        <IconButton onClick={handleModalOpen} width={40}>
          <Logout />
        </IconButton>
      </Box>

      <Modal open={openModal} onClose={handleModalClose} dialogActions={dialogActions} width={600} fullHeight={false}>
        <Grid container sx={styles.logoutModalBodyWrapper}>
          <Grid>
            <Typography variant="h3" gutterBottom>
              {mainDictionary.logoutModalTitle}
            </Typography>
          </Grid>
          <Grid>
            <IconButton onClick={handleModalClose}>
              <Icons.ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Modal>

      {openPortal && (
        <Portal>
          <Loading />
        </Portal>
      )}
    </>
  )
})
