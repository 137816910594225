import { Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { loadingButtonStyles } from './styles'

type Props = {
  text: string
  isLoading: boolean
  disabled: boolean
}
export const LoadingButton = ({ isLoading, text, disabled }: Props) => (
  <Button fullWidth variant={'contained'} type={'submit'} disabled={disabled}>
    {!isLoading ? text : <CircularProgress sx={loadingButtonStyles.loading} size={24} />}
  </Button>
)
