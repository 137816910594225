import { Endpoints } from '@/api/endpoints'
import { INetworkConfig, Instance } from '@/api/instance'
import { IResponse } from '@/api/types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class GamificationOfferApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getGamificationOffer = () => this.get<IResponse<string>>(Endpoints.GamificationOffer)

  approveGamificationOffer = () => this.put<IResponse<boolean>>(Endpoints.GamificationOfferApprove)
}

export const gamificationOfferApi = new GamificationOfferApi(config)
