import { PublicVideosResponse } from '@/types'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class PublicVideosApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getPublicVideos = () => this.get<IResponse<PublicVideosResponse>>(Endpoints.PublicVideos)
}

export const publicVideosApi = new PublicVideosApi(config)
