import { IRatingLevelSort } from '@/stores/rating/types'
import { IPagination } from '../types'

export enum Coverage {
  Course = 2,
  Direction = 1,
  Group = 3,
}

export enum RatingOrderBy {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IRatingParams extends IPagination {
  categoryId?: number
  courseId?: number
  group?: string
  key?: string
  level?: number
  orderBy?: RatingOrderBy
  sortBy?: IRatingLevelSort
  statusId?: string | null
  startDate?: string | Date
  endDate?: string | Date
  officeId?: number | null
  method?: number | null
}

export interface IRatingData {
  studentStatisticsData: IRatingStatistics
  total: number
}

export interface IRatingStatistics {
  statistics: IRatingStatistic[]
  studentStatistic: IRatingStatistic
}

export interface IRatingStatistic {
  coin: number
  courses: IRatingStatisticCourse[]
  firstName: string
  groups: IRatingStatisticGroups[]
  groupsCount: number
  hId: number
  id: number
  lastName: string
  level: number
  photo: string
  position: number
  status: IRatingStatisticStatus
  xp: number
  middleName: string
}

export interface IRatingStatisticGroups {
  beginTime: string
  endedDate: string
  endTime: string
  id: number
  name: string
  startedDate: string
  status: number
  studentsCount: number
}

export interface IRatingStatisticGroup {
  beginTime: string
  categoryName: string
  endedDate: string
  endTime: string
  id: number
  name: string
  staff: string
  startedDate: string
  status: number
  studentsCount: number
}
export interface IRatingStatisticCourse {
  createdAt: string
  id: number
  isStudentCourse: boolean
  name: string
}

export interface IRatingStatisticStatus {
  createdAt: string
  hName: string
  id: number
}

export enum RatingCategoryId {
  Design = 2,
  Marketing = 3,
  Programming = 1,
}

export interface IRatingCategories {
  categories: IRatingCategory[]
  total: number
}

export interface IRatingCategory {
  createdAt: string
  displayName: string
  hName: string
  id: number
  levelsCount: number
}

export interface IRatingLevelByCategories {
  levels: IRatingLevelByCategory[]
  total: number
}

export interface IRatingLevelByCategory {
  category: null
  createdAt: string
  id: number
  requiredXp: number
  _number: number
}

export interface IRatingCourses {
  courses: IRatingStatisticCourse[]
  total: number
}

export type TBranch = {
  id: number
  name: string
}

export type TGetBranchResponse = {
  offices: TBranch[]
  total: number
}

export type Statuses = {
  name: string
  id: number
}

export type GetFilterObjects = {
  statuses: Statuses[]
}
