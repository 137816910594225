import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { TIconButton } from '@/components/Buttons/types'

export const IconButtonStyled = styled(IconButton)<TIconButton>(({ width }) => ({
  height: width ? width : '24px',
  minWidth: 'auto',
  width: width || '24px',
  borderRadius: '8px',
}))

export const TextButtonStyled = styled(Button)({
  padding: '14px 16px',
})
