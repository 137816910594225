import { useEffect, useState } from 'react'
import { useStores } from '@/stores'
import { storage } from '@/utils/storage'

export const useBootstrap = () => {
  const { authStore } = useStores()
  const [isInitiated, setIsInitiated] = useState(true)
  const refreshToken = storage.get('refreshToken')

  const getAppConfigs = async () => {
    try {
      const accessToken = storage.get('accessToken')

      if (accessToken && refreshToken) {
        authStore.setIsAuth(true)
        authStore.setToken({
          accessToken,
          refreshToken,
        })
      }

      setIsInitiated(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getAppConfigs()
  }, [])

  return [isInitiated]
}
