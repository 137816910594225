export const generalDictionary = {
  login: 'Kirish',
  password: 'Parol',
  no: "Yo'q",
  yes: 'Ha',
  lesson: 'Dars',
  phoneNumber: 'Telefon raqam',
  logoutModalTitle: 'Platformadan chiqishni xohlaysizmi?',
  active: 'Faol',
  info: 'Ma`lumot',
  noData: 'Ma`lumot yo`q',
  other: 'Boshqa',
  status: 'Holati',
  all: 'Barchasi',
  toCopyText: 'Nusxalash!',
  copiedText: 'Nusxalandi!',
  ellipseVideo: '-video:',
  errorCode: 'Xato kodi: {code}',
  errorDescription: 'Xatoning tavsifi: {text}',
  attendance: `Yo'qlama`,
}
