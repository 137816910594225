import { makeAutoObservable } from 'mobx'
import { profileApi } from '@/api/profile'
import { Contract, IChangePassword, IProfile } from '@/api/profile/types'
import { NotificationToast } from '@/components/Notifications/notification-toast'
import { SentOtpReq, VerifyOtpReq } from './types'

class ProfileStore {
  isOpenProfileEditModal = false
  isOpenLoginEditModal = false
  isOpenPasswordEditModal = false
  student: IProfile | null = null
  profileImage = false
  profileImageUrl: string | null = null
  isNotificationSettings = false
  confirmationModal = false
  contract: Contract | null = null
  contractLoading = false
  myContracts: Contract[] | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setMyContracts = (params: Contract[] | null) => {
    this.myContracts = params
  }

  setContractLoading = (contractLoading: boolean) => {
    this.contractLoading = contractLoading
  }

  setConfirmationModal = (confirmationModal: boolean) => {
    this.confirmationModal = confirmationModal
  }

  setContract = (params: Contract | null) => {
    this.contract = params
  }

  setStudent = (student: IProfile | null) => {
    this.student = student
  }

  setProfileImageUrl = (params: string | null) => {
    this.profileImageUrl = params
  }

  setProfileImage = (params: boolean) => {
    this.profileImage = params
  }

  setIsOpenProfileEditModal = (isOpen: boolean) => {
    this.isOpenProfileEditModal = isOpen
  }

  setIsOpenLoginEditModal = (isOpen: boolean) => {
    this.isOpenLoginEditModal = isOpen
  }

  setIsOpenPasswordEditModal = (isOpen: boolean) => {
    this.isOpenPasswordEditModal = isOpen
  }

  getMyContracts = () =>
    profileApi
      .getMyContracts()
      .then((res) => {
        if (res.success) {
          this.setMyContracts(res.data)
        }
      })
      .catch(NotificationToast)

  getContract = (id: string) =>
    profileApi
      .getContract(id)
      .then((res) => {
        if (res.success) {
          this.setContract(res.data)
        }
      })
      .catch(NotificationToast)
      .finally(() => this.setContractLoading(false))

  sentOtp = (params: SentOtpReq) => profileApi.sentOtp(params)

  getProfile = () =>
    profileApi
      .getProfile()
      .then((res) => {
        if (res.success) {
          this.setStudent(res.data)
        }
      })
      .catch(NotificationToast)

  contractAccept = (params: VerifyOtpReq) => profileApi.accept(params)

  changeProfilePassword = (params: IChangePassword) => profileApi.changeProfilePassword(params)

  reset() {
    this.isOpenProfileEditModal = false
    this.isOpenLoginEditModal = false
    this.isOpenPasswordEditModal = false
  }
}

export const profileStore = new ProfileStore()
