import { observer } from 'mobx-react-lite'
import { useStores } from '@/stores'
import { Menu } from './Menu'
import { DrawerStyled } from './style'

export const Sidebar = observer(() => {
  const { appStore } = useStores()

  return (
    <DrawerStyled variant={'permanent'} open={appStore.isOpenSidebar}>
      <Menu open={appStore.isOpenSidebar} />
    </DrawerStyled>
  )
})
