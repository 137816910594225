import { makeAutoObservable } from 'mobx'
import { appApi } from '@/api/app'
import { Endpoints } from '@/api/endpoints'
import { addAxiosErrorNotification } from '@/components/Notifications'
import { ILogSendRequestParams, TErrors, TInitial, TMailType, TMailTypeTheme, TMailTypeThemeStructure } from './types'

export class AppStore {
  initialParams: Partial<TInitial> | null = null
  mailTypeThemeStructure: TMailTypeThemeStructure | null = null
  mailTypeList: TMailType[] | null = null
  mailTypeThemeList: TMailTypeTheme[] | null = null
  errorParams: TErrors | null = null
  isRender = false
  canCreateAppeal = false
  logoutLinks = ''
  isOpenSidebar = true
  notificationCount = 0

  constructor() {
    makeAutoObservable(this)
  }

  setNotificationCount = (params: number) => {
    this.notificationCount = params
  }

  setInitialParams = (params: Partial<TInitial>) => {
    this.initialParams = params
    this.mailTypeList = params.mailTypeList || null
    this.mailTypeThemeList = params.mailTypeThemeList || null
    this.canCreateAppeal = params.permissions?.canCreateAppeal || false
    this.mailTypeThemeStructure = this.getMailTypeThemeStructureFromLists(params.mailTypeList, params.mailTypeThemeList)
  }

  getMailTypeThemeStructureFromLists = (
    mailTypeList?: TMailType[],
    mailTypeThemeList?: TMailTypeTheme[],
  ): TMailTypeThemeStructure | null => {
    if (!mailTypeList || !mailTypeThemeList) {
      return null
    }

    const mailTypeThemeStructure: TMailTypeThemeStructure = {}

    mailTypeList.forEach((mailTypeItem) => {
      mailTypeThemeStructure[mailTypeItem.typeId] = mailTypeThemeList.filter(
        ({ typeId }) => typeId === mailTypeItem.typeId,
      )
    })

    return mailTypeThemeStructure
  }

  getNotificationsCount = () =>
    appApi
      .getNotification()
      .then((res) => {
        if (res.success) {
          this.setNotificationCount(res.data || 0)
        }
      })
      .catch(addAxiosErrorNotification)

  setRenderStatus = (status: boolean) => {
    this.isRender = status
  }

  setErrorParams = (params: TErrors | null) => {
    this.errorParams = params
  }

  getInitial = async () => {
    const response = await appApi.getInitial()

    if (response.success) {
      await this.setInitialParams(response.data)
    } else {
      const { errId, errMsg } = response.error

      this.setErrorParams({ title: String(errId), description: errMsg })
    }

    this.setRenderStatus(true)

    return response
  }

  logout = async () => {
    const { origin } = window.location

    await appApi.logout(`${origin}${Endpoints.Logout}`)
  }

  sendLog = (params: ILogSendRequestParams) => appApi.sendLog(params)

  setLogoutLinks = (link: string) => {
    this.logoutLinks = `${link}${Endpoints.Logout}`
  }

  setIsOpenSidebar = (isOpen: boolean) => {
    this.isOpenSidebar = isOpen
  }

  reset = () => {
    this.initialParams = null
    this.isRender = false
    this.errorParams = null
    this.mailTypeList = null
    this.mailTypeThemeList = null
  }
}

export const appStore = new AppStore()
