export const globalStyles = {
  '*': {
    boxSizing: 'border-box',
    fontFamily: '"SB Sans Text","Helvetica","Arial",sans-serif;',
    fontWeight: 400,
  },

  'html, body': {
    fontSize: '16px',
    height: '100%',
    margin: 0,
    color: '#333',
  },

  '#root': {
    height: '100%',
  },

  'svg:not([fill])': {
    fill: 'currentColor',
  },

  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    appearance: 'none',
    margin: 0,
  },

  'input[type="number"]': {
    appearance: 'textile',
  },
  'b, strong': {
    fontWeight: 'bold',
  },
}
