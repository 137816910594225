import { ToastOptions } from 'react-toastify'

export enum NotificationsTypes {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export type TError = {
  code: string
  system: string
  text: string
  uuid: string
}

export type RepeatingNotificationReturnType = (message: string, options?: ToastOptions) => void

export type RepeatingErrorNotificationReturnType = (error: TError, options?: ToastOptions) => void
