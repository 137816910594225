import { FC, ReactNode } from 'react'
import { Icons } from '@/components/Icons'
import { Notify, NotifyContent, NotifyIcon } from './styles'
import { NotificationsTypes } from './types'

type Props = {
  type?: NotificationsTypes
  title: ReactNode
  closeToast?: () => void
}

const iconByType: Record<NotificationsTypes, ReactNode> = {
  [NotificationsTypes.Info]: <Icons.Info />,
  [NotificationsTypes.Success]: <Icons.Success />,
  [NotificationsTypes.Error]: <Icons.Error />,
  [NotificationsTypes.Warning]: <Icons.NotifyWarning />,
}

export const NotificationToast: FC<Props> = ({ title, type = NotificationsTypes.Info, closeToast }) => (
  <Notify type={type}>
    <NotifyIcon type={type}>{iconByType[type]}</NotifyIcon>

    <NotifyContent type={type}>{title}</NotifyContent>

    <NotifyIcon type={type} onClick={closeToast}>
      <Icons.Close />
    </NotifyIcon>
  </Notify>
)
