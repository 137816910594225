import { Components } from '@mui/material/styles/components'
import { colors } from '@/styles/colors'

export const buttons: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      textTransform: 'inherit',
      boxShadow: 'none',
    },

    contained: {
      color: colors.backgroundPrimary,

      '&.Mui-disabled': {
        backgroundColor: colors.mainPrimary,
        opacity: '0.6',
        color: colors.backgroundPrimary,
      },
    },

    sizeSmall: {
      padding: '6px 16px',
      fontSize: '16px',
      lineHeight: '20px',
    },

    sizeMedium: {
      padding: '8px 24px',
      fontSize: '16px',
      lineHeight: '24px',
    },

    sizeLarge: {
      padding: '12px 24px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}
