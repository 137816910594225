import { EditHomeworkParams, HomeworkSubmissionParams, IGetHomeworkUploadUrl } from '@/stores/group/types'
import { dictionaryWithKeys } from '@/utils'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'
import {
  GetHomeworkParams,
  IExamInfo,
  IExamResult,
  IGetGroupChapters,
  IGroupChapters,
  IGroupInfoData,
  IGroupInfoParams,
  IGroupLessonInfo,
  IGroupLessonParams,
  IGroupLessonVideoNameResponse,
  IGroupLessonVideos,
  IGroupParams,
  IGroupRatingParams,
  IGroupsData,
  IHomeworkChatCome,
  IHomeworkChatsGet,
  IHomeworkSend,
  ISuccessResponse,
  IUploadHomewok,
  LastVideoParamsType,
  MakeHomeworkRes,
  TGetHomeworkFileUrlRes,
} from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class GroupApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getGroups = (params: IGroupParams): Promise<IResponse<IGroupsData>> => this.get(Endpoints.Groups, { params })

  getGroupInfo = (params: IGroupInfoParams): Promise<IResponse<IGroupInfoData>> =>
    this.get(dictionaryWithKeys(Endpoints.GroupInfo, { id: params.id }), {
      params: {
        page: params?.page,
        perPage: params?.perPage,
        staffId: params?.staffId,
      },
    })

  getGroupChapters = (params: IGetGroupChapters): Promise<IResponse<IGroupChapters>> => {
    const { id, ...data } = params

    return this.get(`${Endpoints.LessonsByGroup}/${id}`, { params: data })
  }

  getGroupLesson = (params: IGroupLessonParams): Promise<IResponse<IGroupLessonVideos>> =>
    this.get(dictionaryWithKeys(Endpoints.GroupLesson, { id: params?.groupId }), {
      params: {
        staffId: params?.staffId,
        perPage: 1000,
      },
    })

  editHomework = (params: EditHomeworkParams): Promise<IResponse<IHomeworkChatsGet>> =>
    this.put(Endpoints.HomeworkChatUpdate, params)

  lessonRatingSend = (params: IGroupRatingParams): Promise<IResponse<ISuccessResponse>> =>
    this.post(Endpoints.GroupLessonRating, params)

  getHomeworkChat = (params: GetHomeworkParams): Promise<IResponse<IHomeworkChatsGet>> =>
    this.get(dictionaryWithKeys(Endpoints.GroupHomeworkChats, { groupId: params.groupId }), {
      params: { homeworkId: params.homeworkId },
    })

  uploadHomeworkFile = (params: FormData): Promise<IResponse<IUploadHomewok[]>> =>
    this.post(Endpoints.HomeworkFileUpload, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

  getHomeworkUrl = (params: IGetHomeworkUploadUrl): Promise<IResponse<TGetHomeworkFileUrlRes>> =>
    this.post(Endpoints.HomeworkFileUpload, params)

  getExamResults = (params: number): Promise<IResponse<IExamResult[]>> =>
    this.get(`${Endpoints.Exams}/${params}${Endpoints.Students}`)

  sendHomework = (params: IHomeworkSend): Promise<IResponse<MakeHomeworkRes>> =>
    this.post(Endpoints.HomeworkSend, params)

  getGroupLessonVideoName = (id: number): Promise<IResponse<IGroupLessonVideoNameResponse[]>> =>
    this.get(`${Endpoints.LessonVideoNameByGroup}/${id}`)

  getExam = (params: number): Promise<IResponse<IGroupLessonInfo>> => this.get(`${Endpoints.Exams}/${params}`)

  getExamChats = (params: number): Promise<IResponse<IHomeworkChatCome[]>> =>
    this.get(`${Endpoints.Exams}/${params}${Endpoints.Chats}`)

  sendExam = (params: IExamInfo): Promise<IResponse<ISuccessResponse>> =>
    this.post(`${Endpoints.Exams}/${params?.id}${Endpoints.Chats}${Endpoints.Submit}`, params.data)

  getLastVideo = (params: LastVideoParamsType) => this.post<IResponse<boolean>>(Endpoints.LastVideo, params)

  sendHomeworkSubmission = (params: HomeworkSubmissionParams): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${Endpoints.HomeworkChatSubmissions}`, params)
}

export const groupApi = new GroupApi(config)
