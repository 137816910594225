import { AxiosError } from 'axios'
import { IResponse } from '@/api/types'
import mainDictionary from '@/dictionary'

const PHONE_FORMAT_MASK_FOR_REPLACE = '+7 ($1) $2-$3-$4'

export const copyToClipboard = (text: string) => navigator.clipboard.writeText(text)

export const randomNumberInRange = (min = 0, max = 100): number => Math.floor(Math.random() * (max - min + 1)) + min

export const rgbToHex = (r: number, g: number, b: number) =>
  `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`

export const removeDuplicates = (arr: any[]): any[] => [...new Set(arr)]

export const phoneFormatter = (phone: string): string => {
  const phoneLast10digital = phone.replace(/[^\d]+/g, '').slice(-10)

  return phoneLast10digital.length === 10
    ? phoneLast10digital.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, PHONE_FORMAT_MASK_FOR_REPLACE)
    : phone
}

export const makeErrMsg = (error: AxiosError<IResponse<any>>): string => {
  if (!error.response?.data) {
    return error.message
  }

  const { error: responseError } = error.response.data

  return responseError.errMsg
}

export const consoleWarn = (text: string) => console.warn(text, 'color: red')

export const makeFullName = <T extends { firstName?: string; lastName?: string; middleName?: string }>(
  user: T,
): string => [user?.firstName, user?.lastName, user?.middleName].join(' ') || mainDictionary.unknown

export const capitalizeFirstLetter = (inputString: string): string =>
  inputString.replace(/^./, (str) => str.toUpperCase())

export const calcUploadChunkSize = (fileSize: number): number => {
  const size = fileSize / 1024 / 1024

  if (size > 1000) {
    return 8
  }

  if (size > 500) {
    return 6
  }

  if (size > 100) {
    return 4
  }

  if (size > 50) {
    return 2
  }

  return 1
}
