export const loginDictionary = {
  open: 'Kirish',
  phoneNumberErrorTest: 'Telefon raqam noto`g`ri',
  yourEmail: 'Elektron pochtangiz',
  emailPlaceholder: 'example@najottalim.uz',
  emailErrorText: 'Elektron pochtangizni kiriting',
  loginErrorText: 'Loginingizni kiriting',
  passwordErrorText: 'Passwordingizni kiriting',
  somethingWentWrong: "Biror narsa noto'g'ri ketdi",
  loginValidateMessage: 'Foydalanuvchi nomingizni kiriting!',
  passwordValidateMessage: 'Parolingizni kiriting!',
  wrongFilterOptions: 'Login xato',
  blockedUser: 'Sizning akkauntingiz bloklangan',
}
