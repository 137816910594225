export const styles = {
  button: {
    '&:hover': {
      backgroundColor: 'customColors.backgroundPrimary',
    },
  },
  logoutModalBodyWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  },
}
