import { makeAutoObservable } from 'mobx'
import { dashboardApi } from '@/api/dashboard'
import { GetStudentLessonsByDateRes, ILastLesson, IStudentGroup, TAllCoins } from '@/api/dashboard/types'
import { addAxiosErrorNotification } from '@/components/Notifications'

class DashboardStore {
  studentAwardsByCategory: TAllCoins | null = null
  totalCoin = 0
  lastHomeworkDeadline: string | null = null
  lastLesson: ILastLesson | null = null
  lastLessonGroup: IStudentGroup | null = null
  deadlinePassed = false
  lessonDays: string[] | null = null
  dayLessons: GetStudentLessonsByDateRes[] | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setLessonDays = (days: string[] | null) => {
    this.lessonDays = days
  }

  setDayLessons = (params: GetStudentLessonsByDateRes[] | null) => {
    this.dayLessons = params
  }

  getStudentAwardsByDashboard = () =>
    dashboardApi
      .getStudentAwardsByDashboard()
      .then((res) => {
        if (res?.data && res?.success) {
          this.setStudentAwardsByCategory(res.data)
          this.setStudentTotalCoin(res.data?.coin)
        }
      })
      .catch(addAxiosErrorNotification)

  getStudentLastLesson = () =>
    dashboardApi
      .getStudentLastLesson()
      .then((res) => {
        if (res?.success && res?.data) {
          this.setIsHomeworkDeadline(res.data?.homeworkDeadLine)
          this.setLastLesson(res.data?.lesson)
          this.setLastLessonGroup(res.data?.group)
          this.deadlinePassed = res.data.deadlinePassed
        }
      })
      .catch(addAxiosErrorNotification)

  getDayLessons = (date: string) =>
    dashboardApi
      .getDayLessons(date)
      .then((res) => {
        if (res.success) {
          this.setDayLessons(res.data)
        }
      })
      .catch(addAxiosErrorNotification)

  getLessonDays = () =>
    dashboardApi
      .getLessonDays()
      .then((res) => {
        if (res.success) {
          this.setLessonDays(res.data)
        }
      })
      .catch(addAxiosErrorNotification)

  setStudentAwardsByCategory = (awards: TAllCoins) => {
    this.studentAwardsByCategory = awards
  }

  setStudentTotalCoin = (coin: number) => {
    this.totalCoin = coin
  }

  setIsHomeworkDeadline = (deadline: string) => {
    this.lastHomeworkDeadline = deadline
  }

  setLastLesson = (lesson: ILastLesson) => {
    this.lastLesson = lesson
  }

  setLastLessonGroup = (group: IStudentGroup) => {
    this.lastLessonGroup = group
  }

  reset() {
    this.studentAwardsByCategory = null
  }
}

export const dashboardStore = new DashboardStore()
