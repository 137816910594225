import { NotificationParams, ReadNotificationAllParams } from '@/stores/notifications'
import { NotificationSettingsRequest, NotificationSettingsResponse } from '@/types'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse, ISuccessResponse } from '../types'
import { Notification, NotificationResponse } from './types'

const config: INetworkConfig = {
  baseURL: `${Endpoints.Base}${Endpoints.Notifications}`,
}

class NotificationsApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getNotifications = (params: NotificationParams): Promise<IResponse<NotificationResponse>> =>
    this.get(`${Endpoints.GetAll}`, { params })

  readNotification = (params: number, data?: ReadNotificationAllParams): Promise<IResponse<Notification>> =>
    this.patch(`${params}${Endpoints.Read}`, data)

  getNotification = (params: number): Promise<IResponse<Notification>> => this.get(`/${params}`)

  getNotificationSettings = (): Promise<IResponse<NotificationSettingsResponse>> =>
    this.get(`${Endpoints.Settings}${Endpoints.All}`)

  updateNotificationSettings = (params: NotificationSettingsRequest): Promise<IResponse<ISuccessResponse>> =>
    this.put(`${Endpoints.Settings}`, params)
}

export const notificationsApi = new NotificationsApi(config)
