import { Components } from '@mui/material/styles/components'
import { colors } from '@/styles/colors'

// @ts-ignore
export const tablePagination: Components['MuiTablePagination'] = {
  styleOverrides: {
    root: {
      color: colors.textSecondary,
      fontSize: '16px',
    },

    selectLabel: {
      fontSize: '16px',
    },

    displayedRows: {
      fontSize: '16px',
    },

    actions: {
      color: colors.mainPrimary,
    },
  },
}
