export const general = {
  // Pixels
  pxFull: '999px',
  px32: '32px',
  px30: '30px',
  px28: '28px',
  px24: '24px',
  px20: '20px',
  px16: '16px',
  px14: '14px',
  px12: '12px',
  px10: '10px',
  px8: '8px',
  px5: '5px',

  // Sizes
  full: '100%',
  half: '50%',
  screen: '100vh',
  partOfScreen: '90vh',

  // Flex
  flexCenter: {
    alignItems: 'center',
    display: 'flex',
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },

  flexJustifyCenter: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  // Positions
  relative: 'relative',
  absolute: 'absolute',
  fixed: 'fixed',
  static: 'static',

  // Displays
  flex: 'flex',
  grid: 'grid',
  block: 'block',
  inlineBlock: 'inline-block',

  // Others
  none: 'none',
  auto: 'auto',
  start: 'start',
  center: 'center',
  hidden: 'hidden',
  normal: 'normal',
  inherit: 'inherit',
  pointer: 'pointer',
  visible: 'visible',
  vertical: 'vertical',
  important: '!important',
  transparent: 'transparent',

  ellipseText: {
    whiteSpace: 'nowrap',
    width: '45%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingLeft: '5px',
  },
}
