import React from 'react'
import { Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { general } from 'styles'

interface Props {
  title: string
}

export const EllipseText = ({ title }: Props) => (
  <Tooltip title={title}>
    <Typography sx={general.ellipseText} component={'span'}>
      {title}
    </Typography>
  </Tooltip>
)
