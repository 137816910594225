import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import DehazeIcon from '@mui/icons-material/Dehaze'
import { AppBar, Badge, Box, Button, useScrollTrigger } from '@mui/material'
import { Icons } from '@/components'
import { useStores } from '@/stores'
import { useDeviceMediaQuery } from '@/utils'
import { headerStyles } from './style'
import { User } from './User'

export const Header = observer(() => {
  const { appStore } = useStores()
  const trigger: boolean = useScrollTrigger({
    target: window,
  })
  const { isMobile } = useDeviceMediaQuery()

  const handleBurgerButtonToggle = () => {
    appStore.setIsOpenSidebar(!appStore.isOpenSidebar)
  }

  return (
    <AppBar
      sx={{
        ...headerStyles.header,
        ...(trigger ? headerStyles.backDropFilter : {}),
      }}
      position="fixed"
    >
      <Box sx={headerStyles.headerInner}>
        <Box sx={headerStyles.headerLeft}>
          <Box component={Link} sx={headerStyles.logo} to="/">
            <Icons.Logo />
            <Badge color="warning" badgeContent={'Beta'} showZero />
          </Box>

          {!isMobile && (
            <Button
              onClick={handleBurgerButtonToggle}
              sx={headerStyles.menuButton}
              size="large"
              variant="contained"
              startIcon={<DehazeIcon />}
            />
          )}
        </Box>

        <User />
      </Box>
    </AppBar>
  )
})
