import { appStore } from './app'
import { authStore } from './auth'
import { certificateStore } from './certificates'
import { dashboardStore } from './dashboard'
import { gamificationOfferStore } from './gamificationOffer'
import { groupStore } from './group'
import { notificationsStore } from './notifications'
import { paymentStore } from './payments'
import { pointerStore } from './pointer'
import { profileStore } from './profile'
import { ratingStore } from './rating'
import { shopStore } from './shop'

export const stores = {
  appStore,
  authStore,
  groupStore,
  profileStore,
  paymentStore,
  ratingStore,
  pointerStore,
  dashboardStore,
  gamificationOfferStore,
  shopStore,
  certificateStore,
  notificationsStore,
}

export const resetStores = () => {
  appStore.reset()
  authStore.reset()
  groupStore.reset()
  profileStore.reset()
  paymentStore.reset()
  ratingStore.reset()
  pointerStore.reset()
  dashboardStore.reset()
  gamificationOfferStore.reset()
}
