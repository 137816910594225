export const styles = {
  alignItems: 'center',
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center',
  flexDirection: 'column',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  height: '100%',
  width: '100%',
}
