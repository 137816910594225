import { makeAutoObservable } from 'mobx'
import { gamificationOfferApi } from '@/api/gamificationOffer'
import { addAxiosErrorNotification } from '@/components/Notifications'

class GamificationOfferStore {
  isApproved = false
  gamificationOffer: string | null = null
  updatedTime: string | null = null
  isLoading = true

  constructor() {
    makeAutoObservable(this)
  }

  setIsApproved = (value: boolean) => {
    this.isApproved = value
  }

  setGamificationOffer = (gamificationOffer: string | null) => {
    this.gamificationOffer = gamificationOffer
  }

  setLoading = (value: boolean) => {
    this.isLoading = value
  }

  getGamificationOffer = () =>
    gamificationOfferApi
      .getGamificationOffer()
      .then((res) => {
        if (res?.success) {
          this.setGamificationOffer(res.data)
        }
      })
      .catch(addAxiosErrorNotification)
      .finally(() => {
        this.setLoading(false)
      })

  approveGamificationOffer = () =>
    gamificationOfferApi
      .approveGamificationOffer()
      .then((res) => {
        if (res.success) {
          this.setIsApproved(res.data)
        }
      })
      .catch(addAxiosErrorNotification)

  reset() {
    this.isApproved = false
    this.gamificationOffer = null
    this.updatedTime = null
  }
}

export const gamificationOfferStore = new GamificationOfferStore()
