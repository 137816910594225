import { colors } from '../colors'

export const palette = {
  primary: {
    light: colors.mainLight,
    main: colors.mainPrimary,
    dark: colors.mainPrimaryDark,
    contrastText: colors.textPrimary,
  },

  customColors: colors,
  error: {
    main: colors.statusDanger,
  },
  success: {
    main: colors.statusLightSuccess,
  },
  warning: {
    main: colors.statusLightWarning,
  },
}
