import { makeAutoObservable } from 'mobx'
import { certificateApi } from '@/api/certificates'
import { Certificate, CertificateHomeworkChatRes, CertificateRes, CertificateType } from '@/api/certificates/types'
import { addAxiosErrorNotification } from '@/components'
import { GetHomeworkChatParams } from './types'

class CertificateStore {
  loader = false
  certificates: CertificateRes | null = null
  certificate: Certificate | null = null
  certificateDetail: CertificateType | null = null
  certificateHomeworkChats: CertificateHomeworkChatRes | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setCertificateHomeworkChats = (params: CertificateHomeworkChatRes | null) => {
    this.certificateHomeworkChats = params
  }

  setCertificateDetail = (params: CertificateType | null) => {
    this.certificateDetail = params
  }

  setLoader = (params: boolean) => {
    this.loader = params
  }

  setCertificate = (params: Certificate | null) => {
    this.certificate = params
  }

  setCertificates = (params: CertificateRes | null) => {
    this.certificates = params
  }

  getCertificateDetail = (id: string) =>
    certificateApi
      .getCertificateDetail(id)
      .then((res) => {
        if (res.success) {
          this.setCertificateDetail(res.data)
        }
      })
      .catch(addAxiosErrorNotification)

  getCertificate = (id: string) =>
    certificateApi
      .getCertificate(id)
      .then((res) => {
        if (res.success) {
          this.setCertificate(res.data)
        }
      })
      .catch(addAxiosErrorNotification)

  getCertificates = () =>
    certificateApi
      .getCertificates()
      .then((res) => {
        if (res.success) {
          this.setCertificates(res.data)
        }
      })
      .catch(addAxiosErrorNotification)

  getCertificateHomeworkChats = (params: GetHomeworkChatParams) =>
    certificateApi
      .getCertificateHomeworkChats(params)
      .then((res) => {
        if (res.success) {
          this.setCertificateHomeworkChats(res.data)
        }
      })
      .catch(addAxiosErrorNotification)
}

export const certificateStore = new CertificateStore()
