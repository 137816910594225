import { Theme } from '@mui/material/styles'
import { colors, general } from '@/styles'

export const styles = {
  appBar: (theme: Theme, open: boolean, drawerWidth: number) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      ml: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }),

  layout: {
    display: general.flex,
    flexDirection: { xs: 'column', sm: 'row' },
    height: general.screen,

    '@media (max-width: 574px)': {
      height: general.partOfScreen,
    },
  },

  layoutHeaderBar: {
    backgroundColor: colors.textWhite,
  },

  blurBackdrop: {
    position: general.fixed,
    top: 0,
    left: 0,
    width: general.full,
    height: general.full,
    backdropFilter: `blur(${general.px10})`,
    zIndex: 3,
  },

  hamburgerMenu: {
    mr: 5,
  },
  badge: {
    marginLeft: '25px',
  },
  layoutChildrenWrapper: {
    flexGrow: '1',
    overflow: general.auto,
    backgroundColor: colors.backgroundGray,
    padding: { xs: '60px 10px 10px', sm: '75px 15px 10px' },

    '@media (min-width: 817px)': {
      padding: '90px 30px 0',
    },
  },

  menuButtons: {
    minHeight: 48,
    px: 2.5,
  },

  menuJustify: {
    justifyContent: general.center,
  },

  menuList: {
    display: general.block,
    width: 'calc(100% / 8)',
  },
  menuActiveList: {
    backgroundColor: '#e39f55',

    '& svg': {
      color: colors.textWhite,
    },
  },

  drawer: {
    width: { xs: general.full, md: 'calc(65px)' },
    height: { xs: '50px', md: general.inherit },
    overflowX: 'auto',
    position: 'fixed',
    bottom: 0,
    color: colors.textWhite,
    zIndex: '23',

    '& .MuiPaper-root': {
      boxShadow: `${general.px5} ${general.px5} ${general.px5} ${colors.textBlack}`,
      position: { xs: general.relative, md: general.inherit },
      width: { xs: general.full, md: 'calc(64px + 1px)' },
      height: { xs: '50px', md: general.inherit },

      '& .MuiList-root': {
        display: 'flex',
        p: { xs: 0, md: general.px8 },
      },
    },
  },

  style: {
    height: { sm: 100 },
  },
}
