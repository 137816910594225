type Dictionary = Record<string, string>

type Params = Record<string, string | number>

type Return = (key: string, params?: Params) => string

/***
 * @Example:
 * dictionary: {'text": "Email: {email}, Name: {name}, Age: {age}'}
 * params: {email: 'email@site.ru', name: 'Ivan', age: 105}
 * const i18n = intl(dictionary);
 * i18n('text', params);
 * @return [string] // 'Email: email@site.ru, Name: Ivan, Age: 105'
 * */

export const intl =
  (dictionary: Dictionary): Return =>
  (key, params) => {
    const trimmedKey = key.trim()

    if (!dictionary?.hasOwnProperty(trimmedKey)) {
      return trimmedKey
    }

    let dictItem = dictionary[trimmedKey]

    if (params) {
      Object.keys(params).forEach((paramKey) => {
        const trimmedParamKey = paramKey.trim()
        const searchValue = `{${trimmedParamKey}}`

        dictItem = dictItem.replace(new RegExp(searchValue, 'g'), `${params[trimmedParamKey]}`)
      })
    }

    return dictItem
  }

export const dictionaryWithKeys: Return = (text, params) => {
  let dictItem = text

  if (params) {
    Object.keys(params).forEach((paramKey) => {
      const trimmedParamKey = paramKey.trim()
      const searchValue = `{${trimmedParamKey}}`

      dictItem = dictItem.replace(new RegExp(searchValue, 'g'), `${params[trimmedParamKey]}`)
    })
  }

  return dictItem
}
