import { Endpoints } from '@/api/endpoints'
import { INetworkConfig, Instance } from '@/api/instance'
import { IResponse } from '@/api/types'
import { ILogSendRequestParams } from '@/stores'
import { TInitial } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class AppApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getInitial = (): Promise<IResponse<TInitial>> => this.post(Endpoints.Initial)

  sendLog = (params: ILogSendRequestParams): Promise<IResponse<TInitial>> => this.post(Endpoints.SenderLog, params)

  logout = (link: string): Promise<IResponse<any>> => this.post(link)

  getNotification = (): Promise<IResponse<number>> => this.get(`${Endpoints.Notifications}${Endpoints.Count}`)
}

export const appApi = new AppApi(config)
