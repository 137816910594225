import { general } from '@/styles'

export const basicLayoutStyles = {
  bottomWrapper: {
    display: 'flex',
  },
  content: {
    width: 'calc(100vw - 200px)',
    padding: '20px',
    backgroundColor: 'rgb(238, 242, 246)',
    flex: 1,
    minHeight: 'calc(100vh - 80px)',
    marginTop: '70px',
    borderTopLeftRadius: '6px',
    overflowX: 'auto',
    position: 'relative',
  },
  blurBackdrop: {
    position: general.fixed,
    top: 0,
    left: 0,
    width: general.full,
    height: general.full,
    backdropFilter: `blur(${general.px10})`,
    zIndex: 3,
  },
  layout: {
    height: general.screen,

    '@media (max-width: 574px)': {
      height: general.partOfScreen,
    },
  },
  mobileWrapper: {
    paddingBottom: '20px',
  },
}
