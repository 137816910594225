import { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { SpinnerStyled, SpinnerWrapperStyled } from './styles'

type TProps = {
  isBlur?: boolean
  size?: number
}

export const Spinner: FC<TProps> = ({ isBlur, size = 60 }) => (
  <SpinnerWrapperStyled isBlur={isBlur}>
    <SpinnerStyled size={size}>
      <CircularProgress size={size} />
    </SpinnerStyled>
  </SpinnerWrapperStyled>
)
