import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Icons } from '@/components'

export const GridStyled = styled(Grid)({
  alignContent: 'center',
  display: 'grid',
  justifyContent: 'center',
  textAlign: 'center',
  width: '100%',
})

export const GridContentStyled = styled(Grid)({
  maxWidth: '400px',
})

export const StyledLoup = styled(Icons.Loup)(({ theme }) => ({
  width: '50px',
  height: '50px',
  color: theme.colors.mainPrimary,
}))

export const TypographyStyled = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: '600',
}))
