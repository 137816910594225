import { Link as RouterLink, LinkProps } from 'react-router-dom'
import { ButtonProps, IconButton } from '@mui/material'
import { IconButtonStyled } from './styles'

type TProps = ButtonProps & {
  linkTo: LinkProps['to']
  isIcon?: boolean
}

export const Link: React.FC<TProps> = ({ linkTo, isIcon, ...rest }) => (
  <RouterLink to={linkTo}>{isIcon ? <IconButton {...rest} /> : <IconButtonStyled {...rest} />}</RouterLink>
)
