import { GetCategoriesParams, GetProductsParams, GetTransactionsParams } from '@/stores/shop/types'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'
import { GetCategoriesResponse, GetProductsRes, GetTransactionsRes, Product } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class ShopApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getProducts = (params: GetProductsParams): Promise<IResponse<GetProductsRes>> =>
    this.get(`${Endpoints.Store}${Endpoints.Items}`, { params })

  buyingProduct = (params: number): Promise<IResponse<Product>> =>
    this.post(`${Endpoints.Store}${Endpoints.Transactions}`, { itemId: params })

  getCategories = (params: GetCategoriesParams): Promise<IResponse<GetCategoriesResponse>> =>
    this.get(`${Endpoints.Store}${Endpoints.Categories}`, { params })

  getMyPosessions = (params: GetTransactionsParams): Promise<IResponse<GetTransactionsRes>> =>
    this.get(`${Endpoints.Store}${Endpoints.Transactions}`, { params })
}

export const shopApi = new ShopApi(config)
