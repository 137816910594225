import { Components } from '@mui/material/styles/components'
import { colors } from '@/styles/colors'

export const tooltip: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: {
      backgroundColor: colors.backgroundLightBlack,
      fontSize: '14px',
      lineHeight: '20px',
      padding: '8px 16px',
    },
    arrow: {
      color: colors.backgroundLightBlack,
    },
  },
}
