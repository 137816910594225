import { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { Tab, Tabs as MUITabs } from '@mui/material'
import mainDictionary from '@/dictionary'
import { TabsWithPanelStyles } from './styles'

export type Props = {
  tabs: {
    label: string
    tab?: ReactNode
    labelId?: number
  }[]
  isChapterTabs?: boolean
  activeTabIndex?: number
  onChange?: (activeTabindex: Props['activeTabIndex']) => void
  onTabChange?: (labelId: number | string) => void
  isShowGrade?: number | boolean | undefined
  isRating?: boolean
}

export const TabsWithPanel = ({
  tabs,
  activeTabIndex,
  onChange,
  onTabChange,
  isChapterTabs,
  isShowGrade,
  isRating,
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(activeTabIndex || 0)

  useEffect(() => {
    if (activeTabIndex) {
      setActiveIndex(activeTabIndex)
    }
    if (!isShowGrade) {
      setActiveIndex(0)
    }
  }, [activeTabIndex, isShowGrade])
  const handleChange = (e: SyntheticEvent, newValue: number) => {
    setActiveIndex(newValue)
    onChange?.(newValue)
  }

  const handleTabClick = (id: number | string) => {
    onTabChange?.(id)
  }

  if (!tabs.length) {
    return null
  }

  return (
    <>
      <MUITabs value={activeIndex} onChange={handleChange} sx={TabsWithPanelStyles.tabs(isChapterTabs)}>
        {isRating && <Tab label={mainDictionary.all} onClick={handleTabClick.bind(null, 'asd')} />}
        {tabs.map(({ label, labelId }) => (
          <Tab key={label} label={label} onClick={handleTabClick.bind(null, Number(labelId))} />
        ))}
      </MUITabs>
      {tabs[activeIndex]?.tab}
    </>
  )
}
