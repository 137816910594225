export const dashboardDictionary = {
  selvers: 'Kumushlar:',
  iLevel: 'Bosqich:',
  iXp: 'XP:',
  common: 'Umumiy',
  inGroup: 'Guruhda',
  place: "o'rin",
  lastLesson: 'Oxirgi dars:',
  to: ' gacha',
  more: "Ko'proq",
  close: 'Yopish',
  deadlineFinished: 'Vazifa muddati tugagan',
  curriculum: 'Dars jadvali',
}
