import { FC } from 'react'
import { Button, ButtonProps } from '@mui/material'

const dictionary = {
  minimized: 'свернуть',
  more: 'ещё',
}

type TProps = ButtonProps & {
  isMinimized?: boolean
}

export const More: FC<TProps> = ({ isMinimized, ...rest }) => (
  <Button size="small" {...rest}>
    {isMinimized ? dictionary.more : dictionary.minimized}
  </Button>
)
