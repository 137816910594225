import { createTheme, ThemeOptions } from '@mui/material/styles'
import { colors } from '../colors'
import { components } from './components'
import { palette } from './palette'

export const theme: ThemeOptions = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  colors,
  components,
  palette,
  typography: {
    fontFamily: '"SBSansText", "Helvetica", "Arial", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
})
