import { Navigate } from 'react-router-dom'
import { ProtectedOutlet } from '@/App/router/ProtectedOutlet'
import { ROUTES } from '@/constants'

type Props = {
  isAuth: boolean | null
  isRedirectToOfferPage: boolean
}

export const PrivateRoute = ({ isAuth, isRedirectToOfferPage }: Props) => {
  if (!isAuth) {
    return <Navigate to={ROUTES.login} />
  }

  return <ProtectedOutlet isRedirectToOfferPage={isRedirectToOfferPage} />
}
