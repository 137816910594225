import './styles/normalize.css'

import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import { theme } from '@/styles/mui/theme'
import { App } from './App'
import { months } from './constants/months'

dayjs.extend(updateLocale)
dayjs.extend(timezone)
dayjs.updateLocale('en', { months })
dayjs.tz.setDefault('Asia/Tashkent')

const AppComponent = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<AppComponent />)
