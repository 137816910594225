import { IPagination } from '../types'

export interface IPointerCategories {
  categories: IPointerCategory[]
  total: number
}

export interface IPointerCategory {
  createdAt: string
  displayName: string
  hName: string
  id: number
  levelsCount: number
}

export interface IStudentAwardsParams extends IPagination {
  categoryId?: number
  methodId?: number
  studentId?: number
}

export interface IGetAwardsResponse {
  data: IStudentAward[]
  total: number
}

export interface IStudentAward {
  additionalInfo?: string
  coin: number
  comment: string
  createdAt: string
  id: number
  xp: number
  studentId?: number
}

export enum MethodIds {
  Exam = 3,
  Homework = 2,
  Lesson = 1,
}

export interface IAwardsStatistics {
  coin: number
  firstName: string
  id: number
  lastName: string
  level: number
  nextLevelXp: number
  photo: string
  totalCoin: number
  totalXp: number
  xp: number
}

export interface IAwardsStatisticsLessonStatus {
  Came: 1
  DidntCam: 2
}

export interface IAwardsStatisticsAdditional {
  status: IAwardsStatisticsLessonStatus
  lesson: IAwardsStatisticsLesson
}

export interface IAwardsStatisticsLesson {
  id: number
  name: string
}

export const enum GamificationMethod {
  ByAdmin = 0,
  ParticipateLesson = 1,
  DoHomework,
  ParticipateExam,
  PassExam,
}

export interface IGetStatisticsMethodsResponse {
  id: number
  name: string
  method: number
  totalXp: number
  totalCoin: number
}
