import { Components } from '@mui/material/styles/components'
import { colors } from '@/styles/colors'

export const typography: Components['MuiTypography'] = {
  styleOverrides: {
    root: {
      color: colors.textPrimary,
    },

    h2: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 600,
    },

    h3: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 600,
    },

    h4: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
  },
}
