import { ChangeEvent, useState } from 'react'
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import mainDictionary from '@/dictionary'
import { Scrollable } from '@/styles/scroll'
import { getPaginationCount, paginationShowOption } from './constants'
import { TableStyles } from './styles'
import { TableBodyRow } from './TableBody'
import { DataRow, TableColumn, TablesProps } from './types'

export const Tables = ({
  data,
  columns,
  lastData,
  selectRow,
  onRowClick,
  pagination,
  isNoneColumn,
  dataPointer,
  isSmall,
  loading,
  stickyHeader,
  tableHeight,
  fail,
}: TablesProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleRowClick = (row: DataRow) => {
    onRowClick?.(row)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)

    if (pagination && pagination.handlePageChange) {
      pagination.handlePageChange(newPage)
    }
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)

    if (pagination && pagination?.handleShowSizeChange) {
      pagination.handleShowSizeChange?.(parseInt(event.target.value, 10))
    }
  }

  const isHaveData = data?.length > 0

  return (
    <Paper sx={{ height: tableHeight, ...(!isSmall ? TableStyles.bigTableWrapper : {}) }}>
      <TableContainer sx={{ height: tableHeight }}>
        <Table stickyHeader={stickyHeader} sx={Scrollable}>
          <TableHead>
            <TableRow sx={TableStyles.head}>
              {!isNoneColumn &&
                columns?.map((column: TableColumn) => (
                  <TableCell key={column.key} sx={TableStyles.headRow}>
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>

          {!loading && isHaveData && (
            <TableBody>
              {data.map((row: DataRow, index: number) => (
                <TableBodyRow
                  key={row?.id}
                  row={row}
                  columns={columns!}
                  handleBodyRowClick={handleRowClick}
                  index={index}
                  last={row?.id && row?.id === selectRow?.id}
                  pointer={dataPointer}
                  fail={fail}
                />
              ))}
              {lastData && (
                <TableBodyRow
                  key={lastData?.id}
                  row={lastData}
                  columns={columns}
                  handleBodyRowClick={handleRowClick}
                  index={lastData?.id}
                  last
                />
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {!isHaveData && !loading && <Typography sx={TableStyles.emptyData}>{mainDictionary.emptyData}</Typography>}
      {loading && <Skeleton variant="rounded" height={50} animation="wave" />}
      {pagination && (
        <TablePagination
          rowsPerPageOptions={paginationShowOption}
          component="div"
          count={pagination.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={getPaginationCount}
          labelRowsPerPage={mainDictionary.labelText}
          sx={TableStyles.pagination}
        />
      )}
    </Paper>
  )
}
