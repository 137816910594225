import { userMinBirthYear } from '@/utils/getDataFormat'

export const profileDictionary = {
  gender: 'Jinsi',
  chooseGender: 'Jinsingizni tanlang',
  male: 'Erkak',
  female: 'Ayol',
  save: 'Saqlash',
  birthdayDate: 'Tug’ilgan sana:',
  hhid: 'HH ID',
  firstName: 'Ism',
  example: 'Namuna',
  lastName: 'Familiya',
  fatherName: 'Otasining ismi',
  birthday: "Tug'ilgan sana",
  userInfos: "Shaxsiy ma'lumotlar",
  // eslint-disable-next-line max-len
  editNote:
    'Ism-familiya, tug`ilgan sana va telefon raqamni o’zgartirish uchun markaz administratsiyasiga murojaat qilishingiz mumkin.',
  changePassword: 'Parolni o‘zgartirish',
  mustRequiredMsg: ' Quyidagi ma’lumotlarni to‘ldiring',
  currentPassword: 'Amaldagi parol',
  newPassword: 'Yangi parol',
  checkNewPassword: 'Parolni tasdiqlash',
  enterYourPassword: 'Parolingizni kiriting',
  wrongCheckPassword: 'Parol mos emas',
  checkedPassword: "Kamida 8 xonali bo'lishi kerak",
  successLoginChange: 'Login muvaffaqiyatli o’zgartirildi',
  successPasswordChange: 'Parol muvaffaqiyatli o’zgartirildi',
  successEditProfile: 'Ma’lumotlar muvaffaqiyatli o’zgartirildi',
  imageUploaded: 'Rasm yuklandi',
  imageDeleted: 'Rasm o`chirildi',
  enterYourLogin: 'Loginingizni kiriting',
  myInfo: "Shaxsiy ma'lumotlar",
  unknown: "Noma'lum",
  invalidPassword: 'Amaldagi parol xato',
  hasBeenWaiting: 'Kutayotganlar',
  profileImage: 'Profil rasmi',
  infoAboutImage:
    // eslint-disable-next-line max-len
    "Rasmingiz markazga kirish uchun yuzni tanish qurilmasida qo’llaniladi. Shu sabab uning formati JPEG, JPG yoki PNG bo'lishi, hajmi esa 200 kb dan oshmasligi tavsiya qilinadi. Rasmingizni yuklab saqlaganingizdan so’ng uzog’i bilan 10 daqiqadan so’ng yuzni tanish qurilmasi sizni taniy boshlaydi. Profil rasmini o’chirib yubormang yoki boshqa xil rasmga o’zgartirmang!",
  onlyAvailableTypes: "Rasm .jpg, .jpeg yoki png formatda bo'lishi va hajmi 2MB dan oshmasligi kerak",
  requiredBirthDate: 'Tug`ilgan sananginzi kiriting',
  invalidBirthDate: `Tug'ilgan sana 1900-${userMinBirthYear} oralig'ida bo'lishi kerak`,
  passwordShouldBeDifferent: "Amaldagi va yangi parol bir xil bo'lmasligi kerak",
  publicOffering: 'Ommaviy oferta',
  approved: 'Tasdiqlangan',
  notApproved: 'Tasdiqlanmagan',
  exampleText: '500x500 o`lcham, JPEG, JPG, PNG format, maksimum 2MB',
  waiting: 'Tekshirilmoqda',
  failed: 'Talabga mos kelmadi',
  ready: 'Talabga mos',
  notUpload: 'Yuklanmagan',
  readContract: 'Shartnomani ko`rib chiqish',
  ntLc: "NAJOT TA'LIM MARKAZI",
  signingProgress: 'Shartnoma imzolash jarayonida',
  hasSigned: 'Shartnomani imzoladi',
  contractCurrentStatus: 'Shartnomaning hozirgi holati',
  waitingForSigning: 'Imzolashni kutyapti',
  signed: 'Imzolangan',
  signing: 'Imzolash',
  confirmationContract: 'SMS tasdiqlash',
  weSentCode: 'Ushbu raqamga kod yuborildi',
  enterSMSCode: '6 xonali kodni kiriting',
  code: 'Kod',
  agree: 'Roziman',
  savePdf: 'PDF yuklab olish',
  successfullyAccepted: 'Muvaffaqiyatli o‘zgartirildi',
  myContracts: 'Shartnomalarim',
}
