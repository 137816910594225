import { ScheduleItem } from '@/api/group/types'

export interface TablesProps {
  data: DataRow[]
  lastData?: DataRow | false
  columns: TableColumn[]
  selectRow?: DataRow
  isNoneColumn?: boolean
  onRowClick?: (row: DataRow) => void
  pagination?: IPaginationProps | false
  dataPointer?: TableHoverCursor
  isSmall?: boolean
  loading?: boolean
  stickyHeader?: boolean
  tableHeight?: number | string
  fail?: boolean
}

interface IPaginationProps {
  total: number
  handlePageChange?: (newPage: number) => void
  handleShowSizeChange?: (perPage: number) => void
}

export interface DataRow {
  id?: any
  [key: string]: any
}

export interface TableColumn {
  key: string
  label: string | React.ReactNode
  render?: (value: string, row: DataRow, index: number) => React.ReactNode
}

export interface TableScheduleColumns {
  key: string
  label: string | React.ReactNode
  render?: (value: string, row: ScheduleItem, index: number) => React.ReactNode
}

export type LabelType = {
  from: number
  to: number
  count: number
}

export enum TableHoverCursor {
  Pointer = 'pointer',
}
