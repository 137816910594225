export const enum NotificationBodyTypes {
  JSON = 1,
  HTML,
}

export type Notification = {
  id: number
  title: string
  body: string
  bodyType: NotificationBodyTypes
  isRead: boolean
  createdAt: string
  banner: string
  notificationActionType: number
}

export type NotificationResponse = {
  notifications: Notification[]
  total: number
}
