import { makeAutoObservable } from 'mobx'
import { GetCategoriesResponse, GetProductsRes, GetTransactionsRes, shopApi } from '@/api/shop'
import { addAxiosErrorNotification } from '@/components'
import { GetCategoriesParams, GetProductsParams, GetTransactionsParams } from './types'

const filterDefaultValues = {
  page: 1,
  perPage: 8,
}

class ShopStore {
  buyingModal = false
  products: GetProductsRes | null = null
  productsLoader = false
  productsFilter: GetProductsParams = filterDefaultValues
  categories: GetCategoriesResponse | null = null
  myPosesssions: GetTransactionsRes | null = null
  transactionsLoader = false
  transactionsFilter: GetTransactionsParams | null = filterDefaultValues
  activeIndex = 0

  constructor() {
    makeAutoObservable(this)
  }

  setActiveIndex = (params: number) => {
    this.activeIndex = params
  }

  setTransactionoFilters = (params: GetTransactionsParams | null) => {
    this.transactionsFilter = params
  }

  setTransactionsLoader = (params: boolean) => {
    this.transactionsLoader = params
  }

  setMyPosessions = (params: GetTransactionsRes | null) => {
    this.myPosesssions = params
  }

  setCategories = (params: GetCategoriesResponse | null) => {
    this.categories = params
  }

  setProductFilter = (params: GetProductsParams) => {
    this.productsFilter = params
  }

  setProductsLoader = (params: boolean) => {
    this.productsLoader = params
  }

  setBuyingModal = (params: boolean) => {
    this.buyingModal = params
  }

  setProducts = (params: GetProductsRes | null) => {
    this.products = params
  }

  buyingProduct = (params: number) => shopApi.buyingProduct(params)

  getProducts = (params: GetProductsParams) =>
    shopApi
      .getProducts(params)
      .then((res) => {
        if (res.success) {
          this.setProducts(res.data)
        }
      })
      .catch(addAxiosErrorNotification)
      .finally(() => this.setProductsLoader(false))

  getCategories = (params: GetCategoriesParams) =>
    shopApi
      .getCategories(params)
      .then((res) => {
        if (res.success) {
          this.setCategories(res.data)
        }
      })
      .catch(addAxiosErrorNotification)

  getMyPosessions = (params: GetTransactionsParams) =>
    shopApi
      .getMyPosessions(params)
      .then((res) => {
        if (res.success) {
          this.setMyPosessions(res.data)
        }
      })
      .catch(addAxiosErrorNotification)
      .finally(() => this.setTransactionsLoader(false))
}

export const shopStore = new ShopStore()
