import { FC, PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { useDeviceMediaQuery, usePrintEvent } from '@/utils'
import { DrawerMobile } from '../layout/drawer'
import { Header } from './Header'
import { Sidebar } from './Sidebar'
import { basicLayoutStyles } from './style'

export const BasicLayout: FC<PropsWithChildren> = ({ children }) => {
  const isPrinted = usePrintEvent()
  const { isMobile } = useDeviceMediaQuery()

  return (
    <Box sx={basicLayoutStyles.layout}>
      <Header />
      <Box
        sx={{
          ...basicLayoutStyles.bottomWrapper,
          ...(isMobile ? basicLayoutStyles.mobileWrapper : {}),
        }}
      >
        {!isMobile && <Sidebar />}
        <Box sx={basicLayoutStyles.content}>{children}</Box>
      </Box>

      <Box>{isMobile && <DrawerMobile open={false} />}</Box>

      {isPrinted && <Box sx={basicLayoutStyles.blurBackdrop} />}
    </Box>
  )
}
