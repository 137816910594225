import { makeAutoObservable, runInAction } from 'mobx'
import { pointerApi } from '@/api/pointer'
import {
  IAwardsStatistics,
  IGetAwardsResponse,
  IGetStatisticsMethodsResponse,
  IPointerCategory,
  IStudentAward,
  IStudentAwardsParams,
} from '@/api/pointer/types'
import { addAxiosErrorNotification } from '@/components/Notifications'

class PointerStore {
  activeCategoryId = 0
  categories: IPointerCategory[] = []
  isOpenCommentModal = false
  awardsStatistics: IAwardsStatistics | null = null
  comment: IStudentAward | null = null
  studentStatisticsResults: IGetAwardsResponse | null = null
  statisticsMethods: IGetStatisticsMethodsResponse[] | null = null
  page = 1
  perPage = 10
  constructor() {
    makeAutoObservable(this)
  }

  getCategories = () =>
    pointerApi
      .getPointerCategories()
      .then((res) => {
        if (res?.success && res?.data) {
          this.setCategories(res.data.categories)
          this.setActiveCategoryId(res.data?.categories[0]?.id)
        }
      })
      .catch(addAxiosErrorNotification)

  getAwardsCategories = () =>
    pointerApi
      .getAwardsStatistic()
      .then((res) => {
        if (res?.success && res?.data) {
          this.setAwardsStatistics(res?.data)
        }
      })
      .catch(addAxiosErrorNotification)

  setAwardsStatistics = (awards: IAwardsStatistics) => {
    this.awardsStatistics = awards
  }

  setActiveCategoryId = (id: number) => {
    this.activeCategoryId = id
  }

  setCategories = async (categories: IPointerCategory[]) => {
    this.categories = categories
  }

  getStudentStatistics = (params: IStudentAwardsParams) =>
    pointerApi
      .getStudentAwards(params)
      .then((res) => {
        if (res?.success && res?.data) {
          runInAction(() => {
            this.studentStatisticsResults = res.data
          })
        }
      })
      .catch(addAxiosErrorNotification)

  getStudentStatisticsMethods = () =>
    pointerApi
      .getStudentStatisticsMethods()
      .then((res) => {
        if (res?.success) {
          runInAction(() => {
            this.statisticsMethods = res.data
          })
        }
      })
      .catch(addAxiosErrorNotification)

  setIsOpenCommentModal = (isOpen: boolean) => {
    this.isOpenCommentModal = isOpen
  }

  setComment = (comment: IStudentAward) => {
    this.comment = comment
  }

  resetStudentStatistics = () => {
    this.studentStatisticsResults = null
  }

  setPage = (page: number) => {
    this.page = page
  }

  setPerPage = (perPage: number) => {
    this.perPage = perPage
  }

  reset() {
    this.activeCategoryId = 0
    this.isOpenCommentModal = false
    this.studentStatisticsResults = null
    this.page = 1
    this.perPage = 10
  }
}

export const pointerStore = new PointerStore()
