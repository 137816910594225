import IconButton from '@mui/material/IconButton'
import { Icons } from '@/components'
import { theme } from '@/styles/mui/theme'
import { Menu } from '../menu'
import { styles } from '../styles'
import { DrawerHeaderStyled, DrawerStyled } from './styles'
import { DrawerMobileProp, DrawerProps } from './types'

export const DrawerMui = ({ open, handleDrawerClose }: DrawerProps) => (
  <DrawerStyled variant={'permanent'} open={open}>
    <DrawerHeaderStyled>
      {open && <Icons.Logo />}
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'rtl' ? <Icons.ChevronRightIcon /> : <Icons.ChevronLeftIcon />}
      </IconButton>
    </DrawerHeaderStyled>

    <Menu open={open} />
  </DrawerStyled>
)

export const DrawerMobile = ({ open }: DrawerMobileProp) => (
  <DrawerStyled sx={styles.drawer} variant={'permanent'} open={open}>
    <Menu open={open} />
  </DrawerStyled>
)
