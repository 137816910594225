import { FC, memo } from 'react'
import { Control, useController } from 'react-hook-form'
import { TextField as MuiTextField, TextFieldProps } from '@mui/material'

type TFieldProps = {
  control: Control<any>
  helperText?: string
  label?: string
  name: string
}

export type TTextFieldProps = TFieldProps & TextFieldProps

export const TextField: FC<TTextFieldProps> = memo(({ name, control, label, helperText, ...restProps }) => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name })
  const fieldError = error && error.message ? error.message : error

  return (
    <MuiTextField
      error={!!fieldError}
      fullWidth
      // @ts-ignore
      helperText={fieldError || helperText}
      label={label}
      name={name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={field.ref}
      value={field.value}
      variant="filled"
      color="primary"
      {...restProps}
    />
  )
})
