import { Components, outlinedInputClasses } from '@mui/material'
import { colors } from '@/styles/colors'

export const inputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    root: {
      colors: colors.textPrimary,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
}

export const outlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      borderColor: `${colors.inputBorderColor} !important`,

      [`&:hover:not(.Mui-focused) .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: 'transparent',
      },
    },

    notchedOutline: {
      borderColor: 'transparent',
    },
  },
}

export const inputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      color: colors.textSecondary,
      fontSize: '16px',
      lineHeight: '24px',
    },

    shrink: {
      '&.Mui-focused': {
        color: colors.mainPrimary,
      },
    },
  },
}
