export const shopDictionary = {
  confirmBuyingText: (value: string) =>
    `Ushbu aksessuarni sotib olsangiz sizning hisobingizdan ${value} Kumush yechib olinadi. Sotib olishni davom ettirasizmi?`,
  enoughCoin: 'Kumushingiz yetarli',
  notEnoughCoin: 'Kumushingiz yetarli emas',
  runOut: 'Tugagan',
  successText:
    'Mahsulot muvaffaqiyatli sotib olindi. Sotib olingan mahsulotni 10(o’n) kun ichida markazdan olib ketishingiz so’raladi. Aks holda, savdo bekor qilinadi.',
  onSale: 'Sotuvda',
  possession: 'Sotib olganlarim',
  category: 'Kategoriya',
  priceOfAccessory: 'Akssesuar qiymati',
  nameOfAccessory: 'Akssesuar nomi',
  from: 'dan',
  to: 'gacha',
  isSilverEnough: 'Kumushlarim yetadi',
  value: 'Qiymati: ',
  boughtTime: 'Sotib olingan vaqt: ',
  receivedTime: 'Mahsulot qabul qilingan vaqt: ',
  search: 'Qidirish...',
  noMyPosessions: 'Sotib olingan aksessuarlar mavjud emas',
  notAnyPossessions: 'Aksessuar mavjud emas',
  pickedTime: 'Mahsulot qabul qilingan vaqt: ',
  noAccessories: 'Aksessuarlar mavjud emas',
  runOutMsg: 'Ushbu aksessuar tugagan',
}
