import 'react-toastify/dist/ReactToastify.css'

import { FC } from 'react'
import { ToastContainerStyled } from './styles'

const AUTO_CLOSE = 7000

export const NotificationContainer: FC = () => (
  <ToastContainerStyled
    autoClose={AUTO_CLOSE}
    closeButton={false}
    closeOnClick={false}
    icon={false}
    newestOnTop={false}
    position="top-right"
    rtl={false}
    draggable
    limit={1}
    pauseOnFocusLoss
    pauseOnHover
  />
)
