import { GetHomeworkChatParams } from '@/stores/certificates/types'
import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'
import { Certificate, CertificateHomeworkChatRes, CertificateRes, CertificateType } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class CertificateApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getCertificates = (): Promise<IResponse<CertificateRes>> => this.get(Endpoints.Certificates)

  getCertificate = (id: string): Promise<IResponse<Certificate>> => this.get(`${Endpoints.Certificates}/${id}`)

  getCertificateDetail = (id: string): Promise<IResponse<CertificateType>> =>
    this.get(`${Endpoints.Certificates}/${id}${Endpoints.Detail}`)

  getCertificateHomeworkChats = (params: GetHomeworkChatParams): Promise<IResponse<CertificateHomeworkChatRes>> =>
    this.get(`${Endpoints.Certificates}${Endpoints.homeworkChats}/${params.id}`, { params: params.info })
}

export const certificateApi = new CertificateApi(config)
