import { Box } from '@mui/system'
import classnamesBind from 'classnames/bind'
import { LoadingImage } from '@/components/Icons/icon-files'

import styles from './loading.scss'

const cn = classnamesBind.bind(styles)

export const Loading = () => (
  <Box className={cn('loader')}>
    <Box className={cn('loader__img-wrapper')}>
      <LoadingImage className={cn('loader__img')} />
    </Box>
  </Box>
)
