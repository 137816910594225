import { Components } from '@mui/material/styles/components'
import { colors } from '@/styles/colors'

// @ts-ignore
export const tab: Components['MuiTab'] = {
  styleOverrides: {
    root: {
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0 12px',
      minHeight: 'auto',
      paddingLeft: 0,
      paddingRight: 0,
      textTransform: 'none',

      '&:hover:not(.Mui-selected)': {
        color: colors.textPrimary,
      },
    },
  },
}
