import { awardsDictionary } from './awards'
import { certificatesDictionary } from './certificates'
import { chapterDictionary } from './chapter'
import { dashboardDictionary } from './dashboard'
import { gamificationOfferDictionary } from './gamificationOffer'
import { generalDictionary } from './general'
import { groupDictionary } from './group'
import { homeworkDictionary } from './homework'
import { kinescopeDictionary } from './kinescope'
import { layoutDictionary } from './layout'
import { loginDictionary } from './login'
import { notificationDictionary } from './notifications'
import { paymentDictionary } from './payment'
import { pointersDictionary } from './pointers'
import { profileDictionary } from './profile'
import { ratingDictionary } from './rating'
import { shopDictionary } from './shop'
import { tableDictionary } from './table'

export default {
  ...loginDictionary,
  ...groupDictionary,
  ...kinescopeDictionary,
  ...chapterDictionary,
  ...profileDictionary,
  ...layoutDictionary,
  ...paymentDictionary,
  ...dashboardDictionary,
  ...tableDictionary,
  ...homeworkDictionary,
  ...awardsDictionary,
  ...ratingDictionary,
  ...pointersDictionary,
  ...generalDictionary,
  ...gamificationOfferDictionary,
  ...shopDictionary,
  ...certificatesDictionary,
  ...notificationDictionary,
}
