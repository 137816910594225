import { OutlinedInput, selectClasses } from '@mui/material'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { styled } from '@mui/material/styles'

export const OutlinedInputStyled = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: '8px',

  '& .MuiSelect-outlined': {
    color: theme.colors.textPrimary,
    fontSize: '16px',
    lineHeight: '24px',
    padding: '20px 14px 6px 16px',
    width: '100%',

    '&.MuiOutlinedInput-input': {
      paddingRight: '10px',
    },
  },
}))

export const multiSelectStyles = {
  inputLabel: {
    [`&.${inputLabelClasses.shrink}`]: {
      transform: 'translate(16px, 5px) scale(0.75)',
    },
  },
}

export const formControlStyles = {
  [`& .${selectClasses.icon}`]: {
    right: '12px',
    top: 'calc(50% - 4px)',
  },
}
