export const certificatesDictionary = {
  myCertificates: 'Mening sertifikatlarim',
  certificate: 'Sertifikat',
  requirement: 'Kursni to’liq bitiring va Sertifikatni qo’lga kiriting',
  download: 'Yuklab olish',
  moreResults: 'Batafsil natijalar',
  print: 'Chop etish',
  share: 'Ulashish',
  courseName: 'Kurs nomi',
  startDate: 'Boshlash vaqti',
  endDate: 'Tugash vaqti',
  mainTaskResults: 'Asosiy vazifalar natijalari',
  themeName: 'Mavzu nomi',
  telegram: 'Telegram',
  facebook: 'Facebook',
  copyLink: 'Havolani nusxalash',
  successfullyCopied: 'Muvaffaqiyatli ko`chirildi',
  accepted: 'Qabul qilingan',
  rejected: 'Qaytarilgan',
}
