export const notificationDictionary = {
  notificationTitle: 'Xabarnomalar',
  readAll: 'Barchasini o`qish',
  examStudent: 'Imithon',
  announceExamStudent: 'Imithon e’loni',
  deadlineExamStudent: 'Imtihon vazifa muddati yaqin qolsa',
  homeworkExamStudent: 'Uy vazifa',
  checkHomeworkStudent: 'Uy vazifa tekshirilganda',
  deadlineHomeworkStudent: 'Uy vazifa muddati yaqin qolsa',
  addGroupStudent: 'Guruhga qo’shilganlik',
  stopGroupStudent: 'Guruhda o’qishni to’xtatganlik',
  awardStudent: 'XP, Kumush taqdim etilganda',
  notificationSettings: 'Bildirishnoma sozlamalari',
}
