export const Scrollable = {
  '& .MuiTableRow-root:hover': {
    backgroundColor: '#fff !important',
  },
  '&::-webkit-scrollbar': {
    width: '5px',
    borderRadius: '100px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#dadada',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#ba8d5b',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
}
