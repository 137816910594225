import { ToastContainer } from 'react-toastify'
import { styled } from '@mui/material/styles'
import { NotificationsTypes } from './types'

type TNotify = {
  type: NotificationsTypes
}

const notifyColor = {
  [NotificationsTypes.Error]: 'statusLightDanger',
  [NotificationsTypes.Info]: 'statusLightPrimary',
  [NotificationsTypes.Success]: 'statusLightSuccess',
  [NotificationsTypes.Warning]: 'statusLightWarning',
}

const notifyBgColor = {
  [NotificationsTypes.Error]: 'statusLightSurfaceDanger',
  [NotificationsTypes.Info]: 'statusLightSurfacePrimary',
  [NotificationsTypes.Success]: 'statusLightSurfaceSuccess',
  [NotificationsTypes.Warning]: 'lightWarning',
}

export const ToastContainerStyled = styled(ToastContainer)({
  backgroundColor: 'inherit',
  padding: 0,
  width: '90%',
  maxWidth: '500px',

  '@media only screen and (max-width: 480px)': {
    marginLeft: 'auto !important',
    right: '0 !important',
  },

  '& .Toastify__toast': {
    boxShadow: '0 3px 5px rgb(0 0 0 / 20%), 0 5px 8px rgb(0 0 0 / 14%), 0 1px 14px rgb(0 0 0 / 12%)',
    backgroundColor: 'inherit',
    padding: 0,
  },

  '& .Toastify__toast-body': {
    padding: 0,
    margin: 0,

    '& div:last-child': {
      height: '100%',
    },
  },
})

export const Notify = styled('div')<TNotify>(({ theme, type = NotificationsTypes.Info }) => ({
  backgroundColor: theme.colors[notifyBgColor[type] as keyof typeof theme.colors],
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: '24px 1fr 24px',
  height: '100%',
  minHeight: '56px',
  padding: '18px',
  width: '100%',
}))

export const NotifyContent = styled('div')<TNotify>(({ theme, type = NotificationsTypes.Info }) => ({
  color: theme.colors[notifyColor[type] as keyof typeof theme.colors],
  fontSize: '16px',
  hyphens: 'auto',
  lineHeight: '24px',
  margin: '0 8px',
  wordBreak: 'break-word',
}))

export const NotifyIcon = styled('div')<TNotify>(({ theme, type = NotificationsTypes.Info }) => ({
  alignItems: 'center',
  color: theme.colors[notifyColor[type] as keyof typeof theme.colors],
  display: 'flex',
  height: '24px',
  justifyContent: 'center',
  lineHeight: 0,
}))
