import { Endpoints } from '../endpoints'
import { INetworkConfig, Instance } from '../instance'
import { IResponse } from '../types'
import { GetStudentLessonsByDateRes, ILastLessonData, TAllCoins } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class DashboardApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getStudentAwardsByDashboard = (): Promise<IResponse<TAllCoins>> => this.get(Endpoints.AwardsByDasboard)

  getStudentLastLesson = (): Promise<IResponse<ILastLessonData>> => this.get(Endpoints.LessonAwards)

  getLessonDays = (): Promise<IResponse<string[]>> => this.get(Endpoints.LessonDays)

  getDayLessons = (date: string): Promise<IResponse<GetStudentLessonsByDateRes[]>> =>
    this.get(Endpoints.LessonsByDate, { params: { date } })
}

export const dashboardApi = new DashboardApi(config)
