import { MD5 as md5 } from 'crypto-js'
import { stage } from '@/api/endpoints'
import { ROUTES } from '@/constants'
export const cdnUrl = new URL(stage.cdnHost)

export const generateUniqueKeys = (index: number, anyParam?: string) => md5(`${index}${anyParam}`).toString()

export const makeFileUrl = (partUrl: string): string | undefined => {
  if (!partUrl) {
    return
  }

  return new URL(partUrl, stage.cdnHost).href
}

export const makeStageFileUrl = (partUrl: string, stageUrl?: string): string | undefined => {
  if (!partUrl) {
    return
  }

  return new URL(partUrl, stageUrl).href
}

export const makeHomeworkFileUrl = (partUrl: string): string | undefined => {
  if (!partUrl) {
    return
  }

  return `${stage.cdnHost}/${partUrl}`
}

export const makeShareTelegramUrl = (id: string, studentName: string) => {
  if (!id || !studentName) return

  const origin = window.location.origin
  const certificateUrl = ROUTES.publicCertificate.replace(':id', id)

  return `https://telegram.me/share/url?url=${origin}${certificateUrl}&text=${studentName}`
}

export const makeShareFacebookUrl = (studentName: string, id: string, url: string) => {
  if (!studentName) return ''

  const certificateUrl = ROUTES.publicCertificate.replace(':id', id)

  return `https://www.facebook.com/sharer.php?s=100&p[title]=${studentName}&p[url]=${url}${certificateUrl}`
}
