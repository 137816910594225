import { autocompleteClasses } from '@mui/material/Autocomplete'
import { filledInputClasses } from '@mui/material/FilledInput'
import { inputAdornmentClasses } from '@mui/material/InputAdornment'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { colors } from '@/styles/colors'

export const inputStyles = {
  input: {
    [`& .${outlinedInputClasses.root}`]: {
      paddingRight: '5px',
    },

    [`& .${outlinedInputClasses.input}`]: {
      padding: '13px 14px 12px 14px',
    },

    [`& .${inputAdornmentClasses.root}`]: {
      marginRight: 0,
    },
  },
}

export const autocompleteStyles = {
  width: '100%',

  [`& .${filledInputClasses.root}`]: {
    paddingLeft: '16px',
    paddingTop: '11px',

    [`& .${autocompleteClasses.input}`]: {
      padding: '10px 0 4px 0',
    },

    [`& .${autocompleteClasses.popupIndicator}`]: {
      padding: '10px',
      margin: '0 -8px',
    },

    [`& .${autocompleteClasses.popupIndicatorOpen}`]: {
      color: colors.mainPrimary,
    },
  },
}
