import { GamificationMethod } from '@/api/pointer/types'

export const pointersDictionary = {
  myResults: 'Mening natijalarim',
  xpTh: 'XP',
  comment: 'Izoh',
  resultMonitoring: "Yig'ilgan natijalar monitoringi",
  chapter: 'Mavzu',
  toLesson: 'Darsga ishtirok',
  toHomework: 'Uyga vazifalar',
  toExam: 'Imtihonlar',
  adminComment: 'Admin izohi',
  lessonChapter: 'Dars mavzusi',
  homeworkTime: 'Uyga vazifa berilgan sana',
  examChapter: 'Imtihon mavzusi',
  ball: 'Ball',
  examDate: 'Imtihon sanasi',
  totalXp: "Jami yig'ilgan XP:",
  totalCoins: "Jami yig'ilgan Kumushlar:",
  coins: 'Kumushlar:',
  totalXP: 'jami XP',
  totalCOIN: 'Jami Kumush',

  resultDictionary: (direction: string, xp: number | string, silver: number | string) =>
    `${direction} bo’yicha jami XP - ${xp}, jami kumush - ${silver}`,

  resultDictionaryByAdmin: (xp: number | string, silver: number | string) =>
    `Administratsiya tomonidan berilgan jami XP - ${xp}, jami kumush - ${silver}`,

  noStatus: 'Status topilmadi',
  came: 'Keldi',
  dontCame: 'Kelmadi',
  iWaiting: 'Kutilayapti',
  iRejected: 'Qaytarildi',
  iAccepted: 'Qabul qilindi',
  participateToLesson: 'Darsga ishtirok bo‘yicha ',
  byHomework: 'Uyga vazifa bo‘yicha',
  byParticipateExam: "Imtihondan o`tgani bo'yicha ",
  byPassExam: 'Imtihondan o‘tish bo‘yicha',
  givenByAdministration: 'Administratsiya tomonidan berilgan ',
  results: 'natijalar',
  late: 'Kechikkan',
}

export const statisticsMethodsDictionary: Record<string, string> = {
  [GamificationMethod.ParticipateLesson]: pointersDictionary.participateToLesson,
  [GamificationMethod.DoHomework]: pointersDictionary.byHomework,
  [GamificationMethod.ParticipateExam]: pointersDictionary.byParticipateExam,
  [GamificationMethod.PassExam]: pointersDictionary.byPassExam,
  [GamificationMethod.ByAdmin]: pointersDictionary.givenByAdministration,
}

export const statisticsMoreDictionary = [
  `${pointersDictionary.participateToLesson} ${pointersDictionary.results}`,
  `${pointersDictionary.byHomework} ${pointersDictionary.results}`,
  `${pointersDictionary.byParticipateExam} ${pointersDictionary.results}`,
  `${pointersDictionary.byPassExam} ${pointersDictionary.results}`,
  `${pointersDictionary.givenByAdministration} ${pointersDictionary.results}`,
]
