import { filledInputClasses, inputLabelClasses } from '@mui/material'
import { Components } from '@mui/material/styles/components'
import { colors } from '@/styles/colors'

export const textField: Components['MuiTextField'] = {
  styleOverrides: {
    root: {
      transaction: 'none',

      [`& .${filledInputClasses.root}`]: {
        backgroundColor: colors.backgroundPrimary,
        border: `1px solid ${colors.backgroundLine}`,
        borderRadius: '8px',

        '&:before': {
          display: 'none',
        },

        '&:after': {
          display: 'none',
        },

        '&:hover': {
          backgroundColor: colors.backgroundPrimary,
        },

        '&.Mui-focused': {
          backgroundColor: colors.backgroundPrimary,
          borderColor: colors.mainPrimary,
        },
      },

      [`& .${filledInputClasses.input}`]: {
        padding: '20px 16px 5px 16px',
      },

      [`& .${inputLabelClasses.root}`]: {
        transform: 'translate(16px, 13px) scale(1)',
      },

      [`& .${inputLabelClasses.shrink}`]: {
        transform: 'translate(16px, 5px) scale(0.75)',
      },
    },
  },
}
