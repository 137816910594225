import { INetworkConfig, Instance } from '@/api/instance'
import { IResponse } from '@/api/types'
import { Endpoints } from '../endpoints'
import { IPaymentsData, IPaymentsParams } from './types'

const config: INetworkConfig = {
  baseURL: Endpoints.Base,
}

class PaymentApi extends Instance {
  constructor(config: INetworkConfig) {
    super(config)
  }

  getPayments = (params: IPaymentsParams): Promise<IResponse<IPaymentsData>> => this.get(Endpoints.Payments, { params })
}

export const paymentApi = new PaymentApi(config)
