import { colors, general } from '@/styles'

export const menuStyles = {
  menuList: {
    display: general.block,
    borderRadius: '10px',

    '& div[role=button]': {
      padding: 0,
      borderRadius: '10px',
      marginBottom: '4px',
      color: colors.textSecondary,
    },
  },
  menuListWrapper: {
    padding: '0 10px',
    display: general.block,
  },
  menuActiveList: {
    backgroundColor: 'rgba(186, 141, 91, 0.1)',

    '& .MuiListItemIcon-root, span': {
      color: `${colors.mainPrimary} !important`,
    },
  },
  menuButtons: {
    minHeight: 48,
    px: 2.5,
  },
  menuJustify: {
    justifyContent: general.center,
  },
}
