import { ThemeOptions } from '@mui/material/styles/createTheme'
import { buttons } from './buttons'
import { card } from './card'
import { chip } from './chip'
import { inputBase, inputLabel, outlinedInput } from './input'
import { select } from './select'
import { tablePagination } from './table-pagination'
import { tab } from './tabs'
import { textField } from './text-field'
import { tooltip } from './tooltip'
import { typography } from './typography'

export const components: ThemeOptions['components'] = {
  MuiButton: buttons,
  MuiChip: chip,
  MuiInputBase: inputBase,
  MuiInputLabel: inputLabel,
  MuiOutlinedInput: outlinedInput,
  MuiTab: tab,
  MuiTablePagination: tablePagination,
  MuiTextField: textField,
  MuiTooltip: tooltip,
  MuiTypography: typography,
  MuiCard: card,
  MuiNativeSelect: select,
}
